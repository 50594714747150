import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { transformEntity } from '../utils';

export const fetchInitiatives = async () => {
  const response = (await axios.get(`/initiatives?populate=*`)).data;
  const initiatives = response.data.map((initiative) => {
    return transformEntity(initiative);
  });
  return initiatives;
};

export const initiativesQueryOptions = queryOptions({
  queryKey: ['initiatives'],
  queryFn: fetchInitiatives,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

export const fetchUserInitiatives = async (userId) => {
  const response = (await axios.get(`/initiatives?filters[user_id][$eq]=${userId}&populate=*`)).data;
  const initiatives = response.data.map((initiative) => {
    return transformEntity(initiative);
  });
  return initiatives;
};

export const createUserInitiative = async (data) => await axios.post(`/initiatives`, data);

export const updateUserInitiative = async (id, newdata) =>
  await axios.put(`/initiatives/${id}`, { data: newdata });

export const fetchInitiative = async (id) => {
  const response = (await axios.get(`/initiatives/${id}?populate=*`)).data;
  return transformEntity(response.data);
};

export const claimInitiative = async (id, data) =>
  await axios.put(`/initiatives/claim/${id}`, data).then(() => {
    window.location.reload();
  });

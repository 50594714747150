import { useQuery } from '@tanstack/react-query';
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTranslation } from '../dictionary';
import { indicatorsQueryOptions, getGroupedIndicators } from '../services/IndicatorService';
import { useLocaleStore } from '../store';
import { formatNumberWithCommas } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import RelatedViz from './RelatedViz';
import Trend from './Trend';

const IndicatorCard = ({ d }) => {
  const language = useLocaleStore((state) => state.language);
  const isLinked = !!d.linkeddataset;

  const navigate = useNavigate();

  const { data: group, isLoading } = useQuery({
    ...indicatorsQueryOptions,
    select: (data) =>
      d.group_id && data ? getGroupedIndicators({ id: d.id, groupId: d.group_id, indicators: data }) : null,
  });

  const handleClick = (path) => {
    navigate(path);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const latestData = d.dataset[d.dataset.length - 1] || null;
  const latestDataValue = latestData ? formatNumberWithCommas(latestData.data) : 'n/a';
  const latestDataUnit = latestData && latestData.unit.length === 1 ? latestData.unit : null;
  const latestDataUnitTranslation =
    latestData && latestData.unit.length > 1 ? getTranslation({ key: latestData.unit, language }) : null;

  return (
    <div className="col-md-6 col-xl-4 card-stakeholder-col">
      <div onClick={() => handleClick(`/indicators/${d.id}`)}>
        <div className="card-indicator rounded-3">
          <div className="content">
            <div className="top">
              <div className="left">
                {isLinked ? (
                  <Fragment>
                    <p className="stat-text">{formatNumberWithCommas(d.linkeddataset.count)}</p>
                    <p className="stat-unit">{d.source}</p>
                    <p className="label color-purple">{getTranslation({ key: d.geographic_area, language })}</p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p className="stat-text">
                      {latestDataValue}
                      {latestDataUnit}
                    </p>
                    <p className="stat-unit">{latestDataUnitTranslation}</p>
                    <p className="label color-purple">{getTranslation({ key: d.geographic_area, language })}</p>
                  </Fragment>
                )}
              </div>
              <div className="right">
                {group ? (
                  <div className="related">{!isLoading && <RelatedViz data={group} id={d.id} />}</div>
                ) : (
                  <div className="trend">
                    {d.visualisation_function === 'timeline' && <Trend data={d.dataset} indicator={d} card={true} />}
                  </div>
                )}
              </div>
            </div>

            <div className="bottom">
              <div className="font-serif-m font-400 mb-2">{language === 'nl' ? d.indicator_nl : d.indicator_en}</div>
              <div className="label">
                <LinkScrollTop to={`/indicators/indicator_category/${d.indicator_category}`}>{`${
                  language === 'nl' ? d.indicator_category_nl : d.indicator_category_en
                }`}</LinkScrollTop>
                <span className="mx-1">›</span>
                <LinkScrollTop to={`/indicators/impact_areas_id/${d.impact_areas_id}`}>{`${
                  language === 'nl' ? d.impact_areas_nl : d.impact_areas_en
                }`}</LinkScrollTop>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndicatorCard;

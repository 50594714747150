import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';
import { flattenAttributes } from '../utils';

export const fetchTargets = async () => {
  const response = await axios.get(`/targets`);
  return flattenAttributes(response.data);
};

export const targetsQueryOptions = queryOptions({
  queryKey: ['targets'],
  queryFn: fetchTargets,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

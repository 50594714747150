import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';

export const fetchAbout = async () => {
  const response = await axios.get(`/about-info`);
  return response.data;
};

export const aboutQueryOptions = queryOptions({
  queryKey: ['about'],
  queryFn: fetchAbout,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
  select: (data) => data.data.attributes,
});

import React from 'react';
import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { extent } from 'd3-array';
import { flatten } from 'lodash';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';

const RelatedViz = ({ data, id }) => {
  const svgWidth = 60;
  const svgHeight = 8;
  const margin = 0;
  const width = svgWidth - margin * 2;
  const xScale = scaleLinear({
    range: [0, width],
  });

  const flattenDataset = flatten(data?.map((d) => d.dataset));
  const language = useLocaleStore((state) => state.language);

  return (
    <div className="related-viz-wrapper">
      <div style={{ fontSize: '9px', opacity: 0.5 }}>
        {`${data.length} ${getTranslation({
          key: 'related',
          language,
        })}`}
      </div>
      {data?.map((g, j) => {
        const bounds = extent(flattenDataset, (d) => d.data);
        xScale.domain([bounds[0] / 2, bounds[1]]);
        return (
          <svg height={svgHeight} width={svgWidth} key={`svg-${j}`}>
            <g transform={`translate(${0},${2})`}>
              {g.dataset?.map((d, i) => {
                const barWidth = xScale(Number(d.data)) ?? 0;
                const barHeight = 5;
                const barX = 0;
                const barY = 0;
                return (
                  <g key={`bar-${i}`}>
                    <Bar
                      x={barX}
                      y={barY}
                      width={barWidth}
                      height={barHeight}
                      fill={id === g.id ? '#5100ff' : '#E9ECEF'}
                    />
                  </g>
                );
              })}
            </g>
          </svg>
        );
      })}
    </div>
  );
};

export default RelatedViz;

import { queryOptions } from '@tanstack/react-query';
import axios from '../axios';

export const fetchPrivacy = async () => {
  const response = await axios.get(`/privacy-statement`);
  return response.data;
};

export const privacyQueryOptions = queryOptions({
  queryKey: ['privacy'],
  queryFn: fetchPrivacy,
  staleTime: Infinity,
  refetchOnWindowFocus: false,
});

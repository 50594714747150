import { TextField } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { interestsQueryOptions } from '../services/InterestService';
import { sectorsQueryOptions } from '../services/SectorService';
import { createUserStakeholder } from '../services/StakeholderService';
import { targetsQueryOptions } from '../services/TargetService';
import { cleanData, getCoords, uploadImage } from '../utils';
import FileUpload from './FileUpload';
import InterestSelect from './InterestSelect';
import OpeningHoursForm from './OpeningHours';
import RenderSwitches from './RenderSwitches';

const FormStakeholder = ({ language, setIsOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState([]);

  const interests = useQuery(interestsQueryOptions);
  const { data: sectors } = useQuery(sectorsQueryOptions);
  const { data: targets } = useQuery(targetsQueryOptions);

  const [sectorState, setSectorState] = useState({
    advisory_board: false,
    catering: false,
    distribution: false,
    education: false,
    employment_agency: false,
    farm_food_production: false,
    food_processing: false,
    government_agency: false,
    interest_group: false,
    knowledge_centre: false,
    network: false,
    research_innovation: false,
    retail: false,
    support_centre: false,
    volunteer_organisation: false,
    youth_comminty_work: false,
  });

  const [targetState, setTargetState] = useState({
    b2c: false,
    b2b: false,
  });

  const [targetb2cState, setTargetb2cState] = useState({
    babies: false,
    children: false,
    youth: false,
    students: false,
    elder: false,
    low_income_households: false,
    disabled_people: false,
  });

  const [targetb2bState, setTargetb2bState] = useState({
    farms_food_production: false,
    retailers: false,
    horeca: false,
    distribution: false,
    schools: false,
  });

  const openingHoursFormRef = useRef();
  const queryClient = useQueryClient();

  //TODO: If one of the 3 methods fails, the stakeholder should not be created and everything should be rolled back
  const onSubmit = async (data) => {
    const targetArray = getFilteredArray(targetState);
    const selectedTargetB2C = getFilteredArray(targetb2cState);
    const selectedTargetB2B = getFilteredArray(targetb2bState);
    const selectedTargets = handleTargets(targetArray, selectedTargetB2C, selectedTargetB2B);
    const sectorsArray = getFilteredArray(sectorState);
    const sectors = handleSectorSelection(sectorsArray);
    const interests = getSelectedInterest();

    const imageId = await uploadImage(selectedFile);
    const locationId = await getCoords(data.address);

    const stakeholderData = {
      data: {
        ...data,
        opening_hours: openingHoursFormRef?.current?.getOpeningHours(),
        logo: imageId,
        admin_email: JSON.parse(sessionStorage.getItem('stytch-user'))?.emails[0].email,
        published: new Date(),
        published_at: true,
        user_id: JSON.parse(sessionStorage.getItem('stytch-user'))?.user_id || null,
        //It doesn't matter if you use interest: { connect: interests, }  or interest: interests,. Both are correct as long as the data layout is {id: 1, name: 'name1'}.
        interest: {
          connect: interests,
        },
        sector: {
          connect: sectors,
        },
        target: {
          connect: selectedTargets,
        },
        ...(locationId && {
          location: {
            connect: [
              {
                id: locationId,
              },
            ],
          },
        }),
      },
    };

    createUserStakeholder(cleanData(stakeholderData))
      .then(() => {
        queryClient.invalidateQueries('stakeholders');
        setIsOpen(false);
        enqueueSnackbar(getTranslation({ key: 'submit_success', language }), {
          variant: 'success',
        });
        document.getElementById('userStakeholders').scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        enqueueSnackbar(getTranslation({ key: 'something_wrong', language }), {
          variant: 'error',
        });
        console.error('Error creating stakeholder:', error);
      });
  };

  const getSelectedInterest = () => {
    return selectedInterests.map((interestKey) => ({
      id: interests.data.find((item) => item.name === interestKey)?.id,
      name: interestKey,
    }));
  };

  const getFilteredArray = (state) => Object.keys(state).filter((key) => state[key]);

  const handleTargets = (listType, listb2c, listb2b) => {
    const result = [];
    addTarget(result, listb2c, listType.includes('b2c'), 'undeterminedB2C');
    addTarget(result, listb2b, listType.includes('b2b'), 'undeterminedB2B');
    return result;
  };

  const addTarget = (result, list, include, undetermined) => {
    if (list.length === 0 && include) {
      const target = targets.find((target) => target.name === undetermined);
      result.push({ id: target.id, name: target.name });
    } else if (list.length > 0) {
      list.forEach((item) => {
        const target = targets.find((target) => target.name === item);
        if (target) {
          result.push({ id: target.id, name: target.name });
        }
      });
    }
  };

  const handleSectorSelection = (selectedSectors) =>
    selectedSectors
      .map((name) => {
        const sector = sectors.find((sector) => sector.name === name);
        return sector ? { id: sector.id, name } : null;
      })
      .filter(Boolean);

  const handleStateChange = (stateSetter) => (event) => {
    stateSetter((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div className="form-container p-0">
      <section id="form-wrapper" className="w-100 mx-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="container-fluid narrow w-100">
          <TextField
            id="name"
            label={getTranslation({ key: 'name', language }) + '*'}
            variant="standard"
            name="name"
            {...register('name', { required: true })}
            error={Boolean(errors.name)}
            helperText={errors.name ? getTranslation({ key: 'name_error', language }) : ''}
          />

          <TextField
            id="description_nl"
            label={getTranslation({ key: 'description_nl', language }) + ' *'}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description', { required: true })}
            error={Boolean(errors.description)}
            helperText={errors.description ? getTranslation({ key: 'description_error', language }) : ''}
          />

          <TextField
            id="description_en"
            label={getTranslation({ key: 'description_en', language }) + ' *'}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description_en', { required: true })}
            error={Boolean(errors.description_en)}
            helperText={errors.desciprtion_en ? getTranslation({ key: 'description_en_error', language }) : ''}
          />

          <TextField
            id="email"
            label={getTranslation({ key: 'email_contact', language }) + ' *'}
            variant="standard"
            {...register('contact_email', {
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              },
            })}
            error={Boolean(errors.contact_email)}
            helperText={errors.contact_email ? getTranslation({ key: 'email_error', language }) : ''}
          />

          <div>
            <TextField
              id="address"
              label={getTranslation({
                key: 'location_address',
                language,
              })}
              placeholder={getTranslation({ key: 'address_placeholder', language })}
              variant="standard"
              {...register('address')}
              style={{ width: '100%' }}
            />
          </div>

          <TextField
            id="website"
            label={getTranslation({ key: 'website', language })}
            variant="standard"
            {...register('website', {
              pattern: {
                value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
              },
            })}
            error={Boolean(errors.website)}
            helperText={errors.website ? getTranslation({ key: 'invalid_website_error', language }) : ''}
          />

          <TextField
            id="telephone"
            label={getTranslation({
              key: 'telephone_contact',
              language,
            })}
            variant="standard"
            {...register('contact_tel', {
              pattern: {
                value: /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/,
              },
            })}
            error={Boolean(errors.contact_tel)}
            helperText={errors.contact_tel ? getTranslation({ key: 'phone_number_error', language }) : ''}
          />

          <TextField
            id="outlined-basic"
            label={getTranslation({
              key: 'enterprise_size',
              language,
            })}
            type="number"
            variant="standard"
            {...register('enterprise_size', { valueAsNumber: true })}
            style={{ width: '100%' }}
          />
          {/* NACE-Code
                  <TextField
                    id="outlined-basic"
                    label={getTranslation({ key: 'nace_code', language })}
                    variant="standard"
                    {...register('nacecode')}
                    style={{ width: '100%' }}
                  /> */}

          <OpeningHoursForm language={language} ref={openingHoursFormRef} />

          <InterestSelect
            language={language}
            interests={interests}
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
            register={register}
          />

          <FileUpload file={selectedFile} setFile={setSelectedFile} />

          <div className="col-12 py-2">
            <div className="row mt-4">
              <div className="col-sm-6">
                <RenderSwitches
                  state={Object.keys(sectorState)
                    .slice(0, 8)
                    .reduce((obj, key) => {
                      obj[key] = sectorState[key];
                      return obj;
                    }, {})}
                  label={getTranslation({ key: 'sector', language })}
                  handleChange={handleStateChange(setSectorState)}
                  control={control}
                />
              </div>

              <div className="col-sm-6">
                <RenderSwitches
                  state={Object.keys(sectorState)
                    .slice(8, sectorState.length)
                    .reduce((obj, key) => {
                      obj[key] = sectorState[key];
                      return obj;
                    }, {})}
                  label={null}
                  handleChange={handleStateChange(setSectorState)}
                  control={control}
                />
              </div>
            </div>

            <RenderSwitches
              state={targetState}
              label={getTranslation({ key: 'target', language })}
              handleChange={handleStateChange(setTargetState)}
              control={control}
            />

            <div className="row mt-4">
              {targetState.b2c && (
                <div className="col-sm-6">
                  <RenderSwitches
                    state={targetb2cState}
                    label={getTranslation({ key: 'b2c', language })}
                    handleChange={handleStateChange(setTargetb2cState)}
                    control={control}
                  />
                </div>
              )}

              {targetState.b2b && (
                <div className="col-sm-6">
                  <RenderSwitches
                    state={targetb2bState}
                    label={getTranslation({ key: 'b2b', language })}
                    handleChange={handleStateChange(setTargetb2bState)}
                    control={control}
                  />
                </div>
              )}
            </div>
          </div>
          <button className="button rounded-3 mt-3 w-100" type="submit">
            {getTranslation({ key: 'submit', language })}
          </button>
        </form>
      </section>
    </div>
  );
};

export default FormStakeholder;

import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import EditButton from './EditButton';
import TrendComparedTooltip from './TrendComparedTooltip';

const TrendCompared = ({ group: indicatorGroups, indicator, language }) => {
  const result = indicatorGroups.reduce((acc, group) => {
    group.dataset.forEach(({ year, data }) => {
      acc[year] = acc[year] || { year };
      acc[year][group[`indicator_${language}`]] = data;
    });
    return acc;
  }, {});
  const transformedArray = Object.values(result);

  const uniqueKeys = [...new Set(transformedArray.flatMap((obj) => Object.keys(obj)).filter((key) => key !== 'year'))];

  const lines = uniqueKeys.map((key) => (
    <>
      <Line
        key={key}
        type="monotone"
        dataKey={key}
        strokeWidth="2"
        activeDot={{
          r: indicator[`indicator_${language}`] === key ? 8 : 6,
          style: { fill: '#5100FF', opacity: indicator[`indicator_${language}`] === key ? 1 : 0.25 },
        }}
        style={{ stroke: '#5100FF', opacity: indicator[`indicator_${language}`] === key ? 1 : 0.25 }}
        connectNulls={true}
      />
    </>
  ));

  return (
    <div style={{ position: 'relative', width: '100%', height: 300 }}>
      <EditButton
        datasets={indicator?.dataset}
        indicator={indicator}
        language={language}
        group={indicatorGroups}
        style={{ position: 'absolute', top: 10, right: -10, zIndex: 10 }}
      />

      <ResponsiveContainer width={'100%'} height={300} minHeight={300}>
        <LineChart data={transformedArray} margin={{ top: 10, right: 30, left: 20, bottom: 30 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="year" />
          <YAxis />
          <Tooltip content={<TrendComparedTooltip payload={transformedArray} />} />
          {lines}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrendCompared;

import { scaleLinear } from '@visx/scale';
import { Bar } from '@visx/shape';
import { extent } from 'd3-array';
import { flatten } from 'lodash';
import React, { useMemo } from 'react';
import EditButton from './EditButton';

const RelatedViz = ({ data, id, hover, setHover, indicator, language }) => {
  const svgWidth = 750;
  const svgHeight = 30;
  const margin = 5;
  const width = svgWidth / 2 - margin * 2;

  const flattenDataset = useMemo(() => flatten(data.map((d) => d.dataset)), [data]);
  const bounds = useMemo(() => extent(flattenDataset, (d) => d.data), [flattenDataset]);

  const xScale = useMemo(
    () =>
      scaleLinear({
        range: [0, width],
        domain: [bounds[0] / 2, bounds[1]],
      }),
    [bounds, width],
  );

  return (
    <>
      <div className="related-viz">
        <div className="label">Comparisons</div>
        {data.map((group, j) => (
          <svg height={svgHeight} width={svgWidth} key={`svg-${group.id}`}>
            <g transform={`translate(${0},${2})`}>
              {group.dataset.map((d, i) => {
                const { data: barData } = d;
                const width = xScale(Number(barData)) ?? 0;
                const height = 10;
                const x = 0;
                const y = 10;
                const isHovered = d === hover;
                return (
                  <g key={`bar-${group.id}-${i}`}>
                    <Bar
                      x={x}
                      y={y}
                      width={width}
                      height={height}
                      fill={id === group.id ? (isHovered ? 'black' : '#5100ff') : isHovered ? 'black' : '#E9ECEF'}
                      onMouseEnter={() => setHover(d)}
                      onMouseLeave={() => setHover(null)}
                      style={{ cursor: 'pointer' }}
                    />
                    <text
                      dx={svgWidth / 2 + 10}
                      dy={15}
                      fontSize="12"
                      fill="rgba(0,0,0,0.5)"
                      fontFamily="Sohne, sans-serif"
                    >
                      {group.indicator_nl}
                    </text>
                  </g>
                );
              })}
            </g>
          </svg>
        ))}
      </div>
      <EditButton datasets={indicator?.dataset} indicator={indicator} language={language} group={data} />
    </>
  );
};

export default RelatedViz;

import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export const useLocaleStore = create(
  persist(
    (set, get) => ({
      language: 'nl',
      setLanguage: (lang) => set({ language: lang }),
    }),
    {
      name: 'locale-storage',
      storage: {
        getItem: (name) => localStorage.getItem(name),
        setItem: (name, value) => localStorage.setItem(name, value),
        removeItem: (name) => localStorage.removeItem(name),
      },
    },
  ),
);

export const useStore = create((set, get) => ({
  aboutInfo: {
    Content: 'Informatie over de EcoFoodMap moet hier staan.',
    Content_en: 'Information about the EcoFoodMap goes here.',
    Cities: 'Context rond steden moet hier staan',
    Cities_en: 'Context regarding cities goes here',
  },
  searchOpen: false,
  loading: true,
}));


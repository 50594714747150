import { RectClipPath } from '@visx/clip-path';
import { localPoint } from '@visx/event';
import { Zoom } from '@visx/zoom';
import React, { useEffect, useState } from 'react';
import mapLegendNl from '../assets/images/legend-desktop-nl.svg';
import mapLegend from '../assets/images/legend-desktop.svg';
import mapLegendMobileNl from '../assets/images/legend-mobile-nl.svg';
import mapLegendMobile from '../assets/images/legend-mobile.svg';
import { getTranslation } from '../dictionary';
import { links } from '../links';
import { useLocaleStore } from '../store';
import { isMobileWithTablet } from '../utils';
import SvgNodesEn from './SvgNodesEn';
import SvgNodesNl from './SvgNodesNl';

function SvgComponent({ setSelected, language }) {
  const width = window.innerWidth;
  const height = isMobileWithTablet ? width : window.innerHeight;

  const initialTransform = {
    scaleX: 1,
    scaleY: 1,
    translateX: 0,
    translateY: 0,
    skewX: 0,
    skewY: 0,
  };

  const handleClick = (e) => {
    const id = Number(e.target.parentNode.id);
    const linkedNodes = links.filter((d) => {
      return d.kumu_node_id === id;
    });

    if (linkedNodes[0] && linkedNodes[0].related_indicator1_id) {
      setSelected(linkedNodes);
    } else {
      setSelected(null);
    }
  };

  return (
    <div>
      <Zoom
        width={width}
        height={height}
        scaleXMin={0.5}
        scaleXMax={isMobileWithTablet ? 8 : 4}
        scaleYMin={0.5}
        scaleYMax={isMobileWithTablet ? 8 : 4}
        transformMatrix={initialTransform}
        passive={true}
      >
        {(zoom) => (
          <div className="relative">
            <svg
              viewBox="0 0 4417.293 2922.285"
              width={width}
              height={height}
              style={{ cursor: zoom.isDragging ? 'grabbing' : 'grab' }}
            >
              <RectClipPath id="zoom-clip" width={width} height={height} />
              <rect width={width} height={height} rx={14} fill={'#1c232a'} />
              <rect
                width={'100%'}
                height={'100%'}
                rx={14}
                fill="transparent"
                onTouchStart={zoom.dragStart}
                onTouchMove={zoom.dragMove}
                onTouchEnd={zoom.dragEnd}
                onMouseDown={zoom.dragStart}
                onMouseMove={zoom.dragMove}
                onMouseUp={zoom.dragEnd}
                onMouseLeave={() => {
                  if (zoom.isDragging) zoom.dragEnd();
                }}
                onDoubleClick={(event) => {
                  const point = localPoint(event) || { x: 0, y: 0 };
                  zoom.scale({ scaleX: 1.2, scaleY: 1.2, point });
                }}
              />
              <g transform={zoom.toString()}>
                <g id="connections" style={{ pointerEvents: 'none' }}>
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3282,1046.1c-27.2,118.1-76.2,230.2-144.5,330.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1437,705.5c-11.3,10.8-26.1,17.1-41.8,17.9c-15.6,0.7-31-4.2-43.2-13.9"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1332.4"
                    y1="1629.7"
                    x2="1263"
                    y2="1496.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M888.9,1000.2c-29.1-12.3-55.4-30.5-77-53.5c-21.7-23-38.3-50.3-48.8-80.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3256.3"
                    y1="1030.8"
                    x2="2974.1"
                    y2="1281.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2297.6,1469.3c57.8,55.6,131.4,91.7,210.7,103.4c79.3,11.7,160.3-1.7,231.6-38.2
		c71.3-36.6,129.5-94.5,166.3-165.6"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2575.3"
                    y1="331.5"
                    x2="2496.1"
                    y2="331.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2217.4,1413.1c-64.3-31.7-119.5-79.4-160.3-138.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3149.1,2166.1c-118.2,103.5-263.6,171.1-418.9,194.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3760.9,1571.5c-136.9,71.6-290.1,106.3-444.5,100.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M856.4,1460.4c191.4,75.8,404.3,76.7,596.4,2.5"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3385.7"
                    y1="2098.3"
                    x2="3613.7"
                    y2="1839.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3216.8"
                    y1="1497.5"
                    x2="3154.7"
                    y2="1458.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1666"
                    y1="708.1"
                    x2="2223.5"
                    y2="1388.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2221.6,2225c409.1-52.5,803.3-187.9,1158.2-398.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="4125"
                    y1="1243.5"
                    x2="4019.7"
                    y2="1247.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3256.1,1030.5c-192.8,169.8-430,281-683.9,320.4c-253.9,39.4-513.7,5.4-748.8-98.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3875,1793.2c-130,139.7-289.3,249-466.4,320"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M750.7,1332.8c6.6,23.3,17.1,45.3,30.9,65.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2482"
                    y1="1435.4"
                    x2="2699.8"
                    y2="1427.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3516.7,1043.8c-22.8,68.2-23.2,141.9-1.1,210.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3322.6"
                    y1="1223.4"
                    x2="3160.8"
                    y2="1226.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2128.9,2254.9c-183,99-384.8,158.4-592.2,174.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1930.1"
                    y1="1435.9"
                    x2="1568.7"
                    y2="1440.5"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2368.1"
                    y1="241.6"
                    x2="2404.5"
                    y2="287.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2173.8,2181.9c5.6-190.3-25.3-379.9-91-558.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M700.8,1316.1c-68.4,51.2-146.8,87.5-230.2,106.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2854.9,2098c71.7-105.1,117.7-225.5,134.3-351.6c16.6-126.1,3.3-254.4-38.7-374.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1856.2,2505.5c60-60.3,100.2-137.4,115.3-221.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3705.9,933.4c-18.8,66.8-54.9,127.4-104.7,175.7c-49.7,48.4-111.3,82.7-178.6,99.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1831.6,550.7c39,143.2,106,277.2,197.1,394.3c91.1,117.1,204.6,215,333.8,288"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2891.7,1041.7c-18.9,56.4-51.2,107.4-94.1,148.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2129.5,2206.8c-241.5-137.6-447-330.5-599.7-562.8c-152.7-232.3-248.2-497.4-278.8-773.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2128.8,2208.1c-332.7-176.8-624.6-421.5-856.8-718.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2798.2,2169.5c-184.6,200.4-421.3,345.4-683.7,418.8c-262.4,73.4-540,72.2-801.7-3.4
		c-261.7-75.6-497.2-222.6-680.1-424.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1798.8,1267.5c36.2,51.5,80.9,96.5,132.1,133.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1794.8,369.1c-36.2,57.5-89.1,102.7-151.6,129.4c-62.5,26.7-131.7,33.8-198.3,20.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3372.8,2090.1c32.8-74.4,51-154.4,53.5-235.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1512.5,407.3c72.8,55.2,160,88.2,251.1,95"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3221.2,1646.8c-29.5-16.7-54.8-39.9-73.7-68c-19-28.1-31.2-60.2-35.7-93.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1135.6,797.6c48.4-20.4,93-49.1,131.6-84.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M773,1259.9c61.7-49.8,109.1-114.9,137.6-188.9"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2254.7"
                    y1="367.9"
                    x2="2306.1"
                    y2="446.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1050,1041.4c-36.1,18.9-66.6,46.9-88.4,81.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M739.9,1334.7c8.9,200.2,80,392.6,203.4,550.5c123.4,157.9,293,273.3,485.1,330.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3045.4,2108c98.2-72.7,178.5-166.9,234.7-275.3c56.3-108.4,87-228.3,89.9-350.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2292.2,480.8c-77.4-25.7-149.8-64.7-213.8-115.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M4013,936c-15,170.1-96.2,327.6-226.2,438.4c-130,110.8-298.3,166.1-468.7,153.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3415.6,1437.9c122.8,33.1,251.8,35.8,376,8c124.1-27.8,239.6-85.4,336.5-167.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3410.3,1840.6c-32,74.5-50.4,154.1-54.4,235.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3696.4,1802.4c53.7,7,108.2-0.3,158.2-21.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1212.1,1486.5c-94.4,177.3-141.5,376-136.6,576.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3793.8,1595.6c-27.9,168-97.8,326.3-203,460.1s-242.6,239-399.3,305.8c-156.7,66.7-327.7,92.9-497.2,76.1
		c-169.5-16.8-332-76.1-472.5-172.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M783.3,1285.3c231.6-20.1,464.2,24.6,671.9,129.2"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3971.8"
                    y1="890.5"
                    x2="3920.2"
                    y2="890.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2918.8,1042.7c20.1,71.7,26.6,146.6,18.9,220.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M716.5,1654.3c-104.1-30.3-196.5-91.6-265-175.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3387.9,1820.4c-319.9,176.3-676.3,276.1-1041.2,291.5c-364.9,15.4-728.5-53.9-1062.1-202.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2168.4,636.5c47.1,7.8,95.5,1.2,138.8-19c43.3-20.2,79.4-53,103.7-94.1c24.3-41.1,35.6-88.6,32.4-136.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3937.4,1726.3c112.4-119.9,189.3-268.6,222.1-429.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2213,1444.5c-57.6,11.5-116.8,12-174.6,1.5"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2969.5"
                    y1="1670.3"
                    x2="3204"
                    y2="1670.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1251.7,1844.7c24.6-54.3,37.9-113.1,39-172.7c1.1-59.6-10.1-118.8-32.7-174"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2132.8,2261c-193.9,145.5-419.2,243.5-657.9,286.2c-238.6,42.7-483.9,29-716.3-40.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2030.4,1539.2c-14.4-12.9-25.2-29.2-31.5-47.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2732.7,1267.8c-70.4,115-181.8,199-311.7,235.2c-129.9,36.2-268.7,21.8-388.4-40.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2188.7,350.3c-49.1,25.5-103.4,39.3-158.7,40.1c-55.3,0.8-110.1-11.3-159.9-35.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3644.7,1129.1c-54.8,10-111.1,7-164.6-8.7c-53.4-15.7-102.4-43.7-143.1-81.7"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2178"
                    y1="2182.2"
                    x2="2254.9"
                    y2="1495"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M634.4,1239c141.9-21.8,286.5-18.1,427.1,11s274.8,83.1,396.4,159.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3023.1,973.9c-107.2-71.2-225.8-123.6-350.6-155c-124.8-31.4-254-41.4-382.2-29.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3683.3,1167c-8.2,64.8-38,124.9-84.4,170.8c-46.5,45.9-107,74.8-171.9,82.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2172.7,2280.9c0.7,40.9-13.7,80.7-40.4,111.8"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2302.6"
                    y1="1407.5"
                    x2="2860"
                    y2="1030.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3378.2"
                    y1="1471.5"
                    x2="3419.5"
                    y2="1742.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2234.8,844.1c8.9,101.8-5.5,204.2-42.2,299.6c-36.6,95.4-94.5,181.1-169.3,250.8"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2089.8"
                    y1="668.6"
                    x2="2029.3"
                    y2="749.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3242.8,780.5c91.5,95.2,210.6,159.3,340.5,183.3c129.9,24,264,6.7,383.5-49.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3246.4,1572c-16.5,67-46.4,130-87.7,185.2"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1290.7"
                    y1="815"
                    x2="1335.5"
                    y2="815.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3732.6,933.1c29.5,89,30.5,184.9,2.6,274.4c-27.8,89.5-83,168-157.8,224.5c-74.8,56.5-165.4,88.1-259.1,90.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1023.1,1423.5c137.3-57.2,291.5-58.7,429.8-4.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2243.9,1389c-86.1-223.3-128.6-461-125.3-700.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2979.3,2170.5c-67,80.7-153.8,142.5-252,179.3"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2311.1"
                    y1="1435.8"
                    x2="2379.9"
                    y2="1436.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2919.8,1363.1c-23,87.1-71.5,165.3-139.3,224.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1117.9,2139.3c319.3,156,678.5,211,1029.9,157.6c351.3-53.4,678.1-212.6,936.6-456.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1398.8,1676.7c99.5,15.2,201.1,5.1,295.7-29.2c94.6-34.3,179-91.9,245.6-167.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1283.5,1432.1c54.6-12.7,111.4-13.5,166.4-2.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M738.7,2460.3c39.5-35.1,69.1-80.1,85.7-130.3c16.6-50.2,19.6-103.9,8.8-155.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2001.1,1390.7c16.3-33,24.7-69.3,24.4-106.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3209.3,2098.3c61.2-89.7,107-189.1,135.2-294c28.3-104.9,38.7-213.8,30.9-322.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2873.5,2124.1c341-89.9,649.5-274.5,889.8-532.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3061.1,1213.4c-615.8-207.2-1269.8-275.6-1915.2-200.3"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2393.9"
                    y1="330.9"
                    x2="2286"
                    y2="330.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2917.3,1275.7c-43.8-133.8-127.1-251.1-239.1-336.5c-111.9-85.4-247.1-134.7-387.7-141.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3199.3,792c-45.1,174.7-124.5,338.6-233.5,482.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3631.9,1838.7c-116.3,256-296.7,477.6-523.8,643.4c-227.1,165.8-493.2,270.1-772.4,302.8
		c-279.2,32.7-562.2-7.3-821.5-116.1s-486-282.7-658.3-504.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2217.3,2210.4c254.6-118.9,482.4-288.4,669.2-498.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2182.6,2182.9c46.3-222.4,129.1-435.5,245-630.8c115.9-195.3,263.4-370.1,436.4-517.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2213.5,2203.7c242.6-163.6,453.2-370.4,621.3-609.9c168.1-239.5,290.9-507.9,362.4-791.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3781.1,1509.6c-52.3-103.3-82.4-216.3-88.4-331.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M616.9,2078.5c79-147.3,188.1-276.3,320.2-378.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2127.9,677.8c33.7,198.5,119.1,384.7,247.6,539.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2869.4,972.1c-83.7-60-176.5-106.1-274.9-136.5c-98.4-30.4-201-44.6-304-42.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3054.6,1420.9c-171.8-16.2-336.3-77.7-476.7-178.1c-140.4-100.4-251.7-236.2-322.6-393.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3536,747.8c-162.5-7.7-322.3-44.6-471.6-109.1c-149.4-64.4-285.9-155.3-403-268.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2375.2,462.7c23-21,39.8-47.9,48.6-77.8"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1023.5"
                    y1="1647.6"
                    x2="1453.1"
                    y2="1464.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2386,2412.3c179-92.8,335.1-224.2,457.1-384.7c122-160.5,206.9-346,248.4-543.3"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3243.8"
                    y1="998.1"
                    x2="3117.8"
                    y2="998.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2590.2,297.8c-61.5-68.5-140.8-118.6-229.1-144.7c-88.3-26.1-182.1-27.1-270.9-2.9
		c-88.8,24.1-169.2,72.5-232.2,139.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1277.7,1898c149.2,39.1,305.8,40.7,455.7,4.5c149.9-36.2,288.6-108.9,403.6-211.7
		c115-102.8,202.7-232.5,255.3-377.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3839.4,1524.8c95.5-63.9,173.4-150.6,226.8-252.3c53.3-101.7,80.3-215.2,78.5-330.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1731.4,1251.2c-70.4,35.4-133,84.6-184.1,144.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2906.9,1044.3c1.6,186.7-46.9,370.4-140.4,532"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3486.4,1297c-27.3-7.8-52.7-21.1-74.7-39.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2808,1414c34-7.8,64.6-26.2,87.4-52.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3610.7,1775.3c-56.8-29.9-106.4-71.6-145.6-122.5c-39.1-50.8-66.8-109.5-81.2-172"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2028.1,1425.3c57.6-12.4,117.1-12.9,174.9-1.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3283.3,1046.4c-55.3,271-182.2,522.2-367.5,727.5c-185.3,205.3-422.3,357.1-686.2,439.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3055.3,1435.5c-78,16.1-158.5,16.6-236.7,1.5"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2891.2"
                    y1="1298.1"
                    x2="2807.3"
                    y2="1254.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2720.4,2372.9c106.3,8.9,213.3-4.1,314.4-38c101.1-34,194.3-88.2,273.7-159.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3281.4,1045.9c-25,104.8-83.8,198.5-167.2,266.8c-83.4,68.2-186.9,107.2-294.6,110.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1794.1,1099.6c24.4,107.3,74.7,207,146.5,290.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3563.3,792c-97.2,223.7-238.7,425.3-416,592.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1263,668.5c-174.7-25.3-352.9-0.4-514.1,71.6c-161.1,72-298.5,188.3-396.2,335.3
		c-97.7,147-151.6,318.7-155.6,495.1c-4,176.4,42.2,350.4,133.2,501.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M625.4,2084.4c229.3-262.5,511.8-473.3,828.8-618.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1099.6,1064.4c3.9,16.8,4.4,34.3,1.4,51.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2136.2,2456.4c130.3,69.9,275.4,107.4,423.2,109.3c147.8,1.9,293.9-31.8,425.9-98.2
		c132-66.5,246.1-163.7,332.6-283.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M564.6,1284.4c-27.8,44.5-63.1,83.8-104.4,116.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2077.8,345.2c77.8,24.9,150.4,63.6,214.4,114.3"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3822"
                    y1="1591.4"
                    x2="3881.1"
                    y2="1709.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3401.8,2127.3c201.7-33.1,386.6-132.8,525.1-283.1c138.5-150.4,222.8-342.7,239.3-546.5"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3325.6"
                    y1="1426.2"
                    x2="3164"
                    y2="1425.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1187.8,1890.9c-150.8,15.2-302.6-15.4-435.7-87.8c-133.1-72.4-241.3-183.3-310.4-318.1"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1767.1"
                    y1="1012.3"
                    x2="1657.2"
                    y2="725.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3994.6,930c-26.1,46.4-62,86.6-105.1,117.8c-43.1,31.2-92.5,52.7-144.7,63"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2139.7,2194.3c-161.5-181.5-264.2-407.7-294.6-648.8c-30.4-241.1,13.1-485.7,124.5-701.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M726.6,1333.4c-40.2,157.4-36.4,322.8,11.1,478.1c47.5,155.3,136.8,294.6,258.1,402.6
		c121.3,108,270.1,180.4,429.9,209.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1242.9,1626.8c14.3-40.5,16.5-84.3,6.4-126"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="1998.9"
                    y1="1191.1"
                    x2="1669.3"
                    y2="718.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2181.7,801.2c-136.1-95.7-347.6-114.3-496.3-100.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3246.4,1013.7c-201.2,68.2-415.8,86.9-625.8,54.5c-209.9-32.4-408.9-115-580.1-240.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M749.5,1244.8c33.7-122.9,36.3-252.3,7.6-376.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M805.2,1658.5c140.6-26.8,272.4-88,383.6-178.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2629.2,2365.6c-139.8-11.1-276.4-47.8-402.8-108.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3209.1,1525.8c-135.6,5.7-270.7-20.2-394.6-75.7"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2004.4,1187.8c-99.7-195-160.8-407.3-180.1-625.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1273.4,1908.6c50.8,27.1,94.9,65.2,129.2,111.5c34.2,46.3,57.8,99.6,68.8,156.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M769.1,1712.3c14.6,77.6,45.3,151.2,90.1,216.3c44.8,65,102.6,120,169.8,161.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="2259.9"
                    y1="295.5"
                    x2="2302.2"
                    y2="248"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2448,1288c75.7,57.4,162,99.1,254,122.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1969.6,1386.8c-7.7-38.6-22-75.7-42.2-109.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3242.2,781.1c70.7,76.6,162.9,130.1,264.6,153.3c101.6,23.2,207.9,15.1,304.9-23.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M842.3,2078.7c65.8-131.5,152.4-251.5,256.6-355.3c104.1-103.8,224.4-190.1,356.1-255.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3251.2,971.9c-44.3-28-95.6-43.1-148-43.6c-52.4-0.4-103.9,13.8-148.7,41.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1268.1,1856.9c160.3-135.8,280.5-312.7,347.7-511.7c67.1-199,78.7-412.6,33.3-617.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M3799.3,797.2c0.6,25.2-12.1,48.9-33.4,62.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1827.8,551.6c56.7,308.9,192.9,597.7,395.3,837.9"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2713.5,2345c27.5-16.7,51.2-39.1,69.5-65.6c18.2-26.5,30.7-56.7,36.4-88.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="935"
                    y1="1441.5"
                    x2="870.1"
                    y2="1442.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2026.3,1597.2c-345,245.9-736.1,419.4-1149.9,510.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1397.8,861c16.6,137,76.1,265.3,170.1,366.4c94,101.1,217.6,169.9,353,196.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2301.5,231.1c-64.1,43.4-136.3,73.6-212.2,88.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1444.8,638.6c-17.4-23.3-30.5-49.5-38.8-77.4"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="4098.1"
                    y1="887.9"
                    x2="4073.9"
                    y2="888.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1832.9,2274.4c20.5,67.9,21.8,140.1,3.8,208.6"
                  />
                  <line
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    x1="3259.8"
                    y1="1624.6"
                    x2="3259.3"
                    y2="1584.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M866,2108.5c233.8-57.6,453.9-160.8,647.8-303.5c193.9-142.7,357.8-322.3,482.3-528.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1941.5,1245.3c9.8,4.1,20.7,4.6,30.8,1.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1858.3,355.2c79.3,51.3,171.6,79.1,266.1,80c94.5,1,187.3-24.9,267.6-74.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2248,2729.5c181-21.5,355.3-81.4,511.2-175.8c155.9-94.4,289.8-221,392.8-371.4"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2220.9,2241.8c189.9,41.8,388.4,13.6,559.2-79.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1387.6,553.2c-0.8,31.9-14.5,62.2-38.1,83.8"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2668.5,2324.1c-21.2-159.3-83.3-310.4-180.3-438.6c-97-128.2-225.6-229-373.2-292.6"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M2066.4,1248.5c90.3,44.7,194,54.4,291,27.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 2.8,
                      enableBackground: 'new',
                    }}
                    d="M1276.6,1901.3c237.7,81.8,492.1,102.5,739.8,60.2c247.8-42.3,480.9-146.2,678-302.1"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M2696,1694.2c-0.3,0.6-0.7,1.2-1,1.8c-106.3,194.1-314.9,326-554.8,326c-316.3,0-578.2-229.4-623-528.2"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M2760.9,1508c-0.1,0.7-0.2,1.3-0.3,2c-0.2,1.3-0.5,2.6-0.7,4c-3.9,21.2-8.9,42-14.9,62.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M2763,1309.7c0.2,1.4,0.4,2.8,0.6,4.2c3.1,21.2,5.1,42.7,5.9,64.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M2313.5,807.1c1,0.3,2,0.6,2.9,0.8c192.7,55.1,347.3,198.4,415.6,382.5"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M2075.9,786.5c21.2-2.1,42.7-3.2,64.4-3.2c13.7,0,27.3,0.4,40.9,1.3"
                  />
                  <path
                    style={{
                      opacity: 0.6,
                      fill: 'none',
                      stroke: '#FFFFFF',
                      strokeWidth: 23.025,
                      enableBackground: 'new',
                    }}
                    d="M1512,1359.7c18-257.9,196.5-472.4,438-547.5"
                  />
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1526.4,2437.3c2,0.1,4,0.3,6,0.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.3179,13.3444',
                        }}
                        d="M1545.6,2439.1c74.6,7.9,146.9,30.4,212.9,66"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1764.4,2508.3c1.7,1,3.5,2,5.2,2.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2873,1910.6c1.4-1.4,2.9-2.8,4.3-4.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.9047,12.8968',
                        }}
                        d="M2886.4,1897.3c109.5-110.1,200-237.6,267.8-377.3c68.7-141.7,113.1-293.9,131.4-450.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3286.3,1063.3c0.2-2,0.4-4,0.7-6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2053.2,1192.5c1.2-1.6,2.5-3.1,3.7-4.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.9781,12.9763',
                        }}
                        d="M2064.7,1177.4c63.3-85.5,104.3-185.6,119.1-291.2c15.1-107.7,2.4-217.5-36.7-318.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2144.7,561.3c-0.7-1.9-1.5-3.7-2.2-5.6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3514.3,1356.6c-0.8,1.9-1.5,3.7-2.3,5.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.4625,12.4177',
                        }}
                        d="M3506.7,1373.3c-16.8,33.9-40.8,63.7-70.4,87.5c-31.3,25-67.8,42.4-106.9,50.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3323.4,1513c-2,0.4-3.9,0.7-5.9,1.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M786,794.7c1.8-0.8,3.6-1.7,5.5-2.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.2219,13.2404',
                        }}
                        d="M803.6,786.7c137.9-61.3,285.6-97.8,436.2-107.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1246.4,678.4c2-0.1,4-0.2,6-0.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M391,2153.2c1.4,1.4,2.8,2.9,4.1,4.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.994,12.9935',
                        }}
                        d="M404.2,2166.9c241.6,247.6,552.7,416.3,892.4,483.5c345.9,68.4,704.5,28.2,1026.7-115
				c318.4-141.6,585.8-376.9,766.7-674.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3093.4,1855.3c1-1.7,2.1-3.4,3.1-5.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M4003.6,934c-0.6,1.9-1.2,3.8-1.8,5.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.0824,13.0893',
                        }}
                        d="M3997.7,952.2c-29.5,85.6-80.5,162.2-148.3,222.6c-69.4,61.8-153.7,104.2-244.5,123.1"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3598.5,1299.2c-2,0.4-3.9,0.8-5.9,1.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3493.5"
                        y1="1339.7"
                        x2="3488.6"
                        y2="1343.2"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="3478.8"
                        y1="1350.1"
                        x2="3427.1"
                        y2="1386.7"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3422.2"
                        y1="1390.2"
                        x2="3417.3"
                        y2="1393.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3316,1041.9c0.9,1.8,1.8,3.6,2.7,5.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3324.6,1058.3c40.8,75.9,93.6,144.8,156.3,204.1"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3485.4,1266.6c1.5,1.4,2.9,2.7,4.4,4.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3858.2,935.9c-0.2,2-0.4,4-0.7,6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3855.8,954.5c-10.8,71-39.2,138.2-82.8,195.4c-44.9,59-104.2,105.2-172.2,134.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3595,1286.8c-1.8,0.8-3.7,1.5-5.6,2.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2165.1,490.7c1.9-0.6,3.8-1.1,5.7-1.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2182.7,485.2c74.2-24.1,143.5-61.5,204.4-110.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2392,371c1.5-1.3,3.1-2.5,4.6-3.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1311.2,720.5c0.1,2,0.2,4,0.4,6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1312.4,739.3c15.2,220.6,71.1,436.6,165.1,636.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1480.2,1381.9c0.9,1.8,1.7,3.6,2.6,5.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M590.6,1200c0.1-2,0.1-4,0.2-6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M591.3,1181.1c7.3-156.3,51.2-308.9,128.3-445.3c80.2-141.9,193.9-262.1,331.2-349.9
				c137.3-87.9,294-140.8,456.5-154.3c158.3-13.1,317.4,11.8,464.1,72.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1977.4,306.5c1.8,0.8,3.7,1.6,5.5,2.3"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M4128.7,1259.7c-1.8,0.8-3.7,1.6-5.5,2.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M4111.2,1267.1c-133.3,53.9-280.7,63.1-419.9,25.9c-141.3-37.8-266.1-121.2-355-237"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3332.4,1050.8c-1.2-1.6-2.4-3.2-3.6-4.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2986.8,2095.3c-1-1.8-1.9-3.5-2.9-5.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2977.7,2078.6C2695,1558,2514.9,988,2447.3,399.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2446.6,392.9c-0.2-2-0.4-4-0.7-6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1034.7,2137.2c-1.8,0.8-3.7,1.6-5.5,2.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1017.3,2144.5c-93.4,37.8-193.2,57.5-294,58.3c-103,0.7-205.1-18.4-300.8-56.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M416.6,2144c-1.9-0.8-3.7-1.5-5.6-2.3"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1971.7,2184.5c-0.4-2-0.9-3.9-1.3-5.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1967.7,2166c-45.6-217.5-46.6-442.1-2.9-660"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1966.1,1499.7c0.4-2,0.8-3.9,1.2-5.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2749.3,1184c-0.3-2-0.7-3.9-1-5.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2746,1165.1c-21.9-118.3-63.3-232.3-122.3-337.3c-60.1-106.9-137.8-202.9-229.7-284"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2388.9,539.5c-1.5-1.3-3-2.6-4.5-3.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1674.7,2260.9c1.5,1.3,3,2.6,4.5,4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1688.3,2273.3c56.5,53.6,97,122,116.8,197.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1806.7,2476.7c0.5,1.9,0.9,3.9,1.4,5.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1523,2252c1.8,0.9,3.6,1.8,5.4,2.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1540.3,2260.9c97.5,51.9,180.6,127.1,242,218.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1786,2485.4c1.1,1.7,2.2,3.4,3.3,5"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1457.9,422.2c-0.7,1.9-1.5,3.7-2.4,5.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1448.9,440.4c-3.4,5.9-7.3,11.5-11.5,16.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1432.8,462.7c-1.3,1.5-2.7,3-4.1,4.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1472.3,721.3c0,2,0,4,0.1,6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1472.7,740.3c4.2,139.8,47,275.8,123.9,392.9c78,118.9,187.9,213.4,316.9,272.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1919.4,1408.7c1.8,0.8,3.7,1.6,5.5,2.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3924.8,1225c-1.7-1.1-3.4-2.1-5.1-3.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3908.9,1214.8c-143.8-95.2-251.7-235.9-306.5-399.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3600.4,809.2c-0.6-1.9-1.2-3.8-1.8-5.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1505.3,414.9c1.3,1.5,2.6,3.1,3.9,4.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1517.8,429.3c65.9,73.1,153.7,123.3,250.5,142.9c98.9,20,201.6,7,292.2-36.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2066.2,532.4c1.8-0.9,3.6-1.8,5.3-2.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1716.5,1531.3c-1.7,1.1-3.3,2.2-5,3.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1700.1,1541.9c-85.7,54.1-180.3,92.9-279.3,114.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1414.2,1657.9c-2,0.4-3.9,0.8-5.9,1.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3298.5,1644.9c1.7-1.1,3.3-2.2,5-3.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3314.4,1634.1c89.5-63,157.8-151.8,195.8-254.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3512.5,1373.3c0.7-1.9,1.3-3.8,2-5.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3632.2,1755.3c-0.8-1.8-1.7-3.6-2.5-5.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3624.4,1738c-49-112.4-78.3-232.4-86.5-354.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3537.5,1376.8c-0.1-2-0.2-4-0.3-6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2034.9,771.1c1.7-1,3.5-2,5.2-3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2051.3,761.4c91.9-55.4,173.8-125.9,242.3-208.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2297.8,547.8c1.3-1.5,2.5-3.1,3.8-4.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M900.6,1203.7c-1.4,1.5-2.7,2.9-4.1,4.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M887.7,1218.2c-37.3,44.7-61,99.3-68.1,157.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M818.8,1382.1c-0.2,2-0.4,4-0.5,6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2244.6,2716.9c1.8-0.8,3.7-1.6,5.5-2.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2262,2709.3c184.7-82.1,352.6-197.8,495.3-341.1c144.2-144.9,259.9-315.5,341.1-503"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3101,1859.2c0.8-1.8,1.6-3.7,2.4-5.5"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="2296.7"
                        y1="522.1"
                        x2="2291.6"
                        y2="525.2"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="2280.7"
                        y1="531.8"
                        x2="2181.6"
                        y2="591.6"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="2176.2"
                        y1="594.8"
                        x2="2171"
                        y2="597.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3484.7"
                        y1="999.7"
                        x2="3478.7"
                        y2="999.7"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="3465"
                        y1="999.5"
                        x2="3366.4"
                        y2="998.6"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3359.5"
                        y1="998.5"
                        x2="3353.5"
                        y2="998.5"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2743.1,1472.3c-0.7,1.9-1.4,3.8-2,5.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2736.5,1490.1c-68.4,181.8-181.4,343.6-328.7,470.4c-149,128.3-328,216.5-520.3,256.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1881.2,2218.4c-2,0.4-3.9,0.8-5.9,1.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3920.2,1264.7c-1.9,0.7-3.8,1.3-5.7,2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3901.9,1271c-95.3,31.6-195.3,46.2-295.7,43.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3599.6,1314c-2-0.1-4-0.2-6-0.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="1105.1"
                        y1="1062.9"
                        x2="1107"
                        y2="1068.6"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="1111.1"
                        y1="1080.6"
                        x2="1211.7"
                        y2="1374.2"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="1213.8"
                        y1="1380.2"
                        x2="1215.7"
                        y2="1385.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3533.2,1360.6c0,2,0,4-0.1,6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3532.9,1379.5c-3.5,122.5-29.8,243.2-77.5,356.1"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3452.9,1741.6c-0.8,1.8-1.6,3.7-2.4,5.5"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2058.2,2461.2c-1.6,1.2-3.3,2.3-4.9,3.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2042.9,2471.7c-45.5,29.6-96.6,49.6-150.1,58.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1886.7,2531.5c-2,0.3-4,0.6-5.9,0.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3058.7"
                        y1="1228.1"
                        x2="3052.7"
                        y2="1228.1"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="3040"
                        y1="1228.2"
                        x2="2825.9"
                        y2="1228.8"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="2819.5"
                        y1="1228.9"
                        x2="2813.5"
                        y2="1228.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M4129.4,1222.2c-1.8-0.9-3.6-1.7-5.4-2.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M4112.2,1214.2c-111.6-50.9-231.6-81-354-88.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3751.7,1125c-2-0.1-4-0.2-6-0.3"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1981.3,1215.6c-1.9-0.6-3.8-1.1-5.8-1.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1963.2,1210.1c-109-34.8-208.4-94.8-290-175.3c-83.2-82-145.3-182.7-181.3-293.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1489.8,734.9c-0.6-1.9-1.2-3.8-1.8-5.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3337.5,1256.3c-1.3,1.5-2.7,3-4,4.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3324.3,1270.5c-45.8,48.1-98.6,88.9-156.6,121.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3161.9,1394.9c-1.8,1-3.5,1.9-5.3,2.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1103.6,2156.4c1.2,1.6,2.3,3.2,3.5,4.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1114.9,2171.7c118.8,160.3,270.7,293.2,445.5,389.6c176.7,97.5,372.5,155.3,573.8,169.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2140.7,2731.3c2,0.1,4,0.3,6,0.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2306.5,2457.5c-1.7,1.1-3.4,2.1-5.1,3.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2290.4,2467.2c-119.8,70.2-259.3,99.5-397.3,83.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1886.7,2549.7c-2-0.3-4-0.5-5.9-0.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1806.6,1200.3c1.5-1.3,3-2.6,4.5-3.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1821,1187.7c200-176.3,364.8-388.8,485.7-626.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2309.6,555.4c0.9-1.8,1.8-3.6,2.7-5.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M941.8,1063.1c0.4,2,0.9,3.9,1.4,5.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M946.9,1080.6c6.2,16.5,15.9,31.6,28.4,44.2c14,14.1,31,24.5,49.8,30.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1031,1157.2c1.9,0.5,3.9,1,5.8,1.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="1118.9"
                        y1="853.9"
                        x2="1122.2"
                        y2="858.9"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="1129.3"
                        y1="869.6"
                        x2="1468.4"
                        y2="1380.8"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="1471.9"
                        y1="1386.2"
                        x2="1475.3"
                        y2="1391.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1810.2,375.5c-0.4,2-0.9,3.9-1.2,5.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1806.9,394.6c-1.8,14.8-1.8,29.7,0,44.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1807.8,445.7c0.3,2,0.7,3.9,1,5.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2681.7,2324.2c0.3-2,0.6-4,0.9-5.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2684.5,2305.5c50.5-344.4,22.9-695.8-80.7-1028.2c-104.3-334.5-282.9-641-522.4-896.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2077,376c-1.4-1.5-2.7-2.9-4.1-4.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3034.1,962.2c-1.2-1.6-2.4-3.2-3.6-4.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3022.8,946.9c-112.2-148.9-249.3-277.3-405.3-379.5c-157.9-103.4-332.6-178.3-516.2-221.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2094.9,344.6c-1.9-0.4-3.9-0.9-5.8-1.3"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1800.5,1507.1c2,0.1,4,0.1,6,0.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1818.3,1507.2c36.2-1.5,71.4-12.2,102.2-31.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1925.6,1472.8c1.7-1.1,3.3-2.2,5-3.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1104.7,1214.7c0.6,1.9,1.1,3.8,1.7,5.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1110.4,1233.1c18.2,54.9,45.2,106.4,80,152.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1194.5,1391.1c1.2,1.6,2.5,3.2,3.7,4.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3343.9,1389.7c-1.2-1.6-2.4-3.2-3.6-4.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3332.7,1375.1c-43.6-54.2-100.2-96.5-164.5-122.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3162.5,1249.9c-1.9-0.7-3.7-1.5-5.6-2.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3486"
                        y1="1323.8"
                        x2="3480.2"
                        y2="1325.4"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="3467.3"
                        y1="1328.9"
                        x2="3174.3"
                        y2="1407.1"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3167.8"
                        y1="1408.8"
                        x2="3162"
                        y2="1410.3"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2131.8,2203.2c-1.6-1.1-3.3-2.3-4.9-3.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2116.2,2192.2c-186.6-133.2-330.6-317.9-414.1-531.7c-85.9-219.7-104-460.1-52-690.2
				c51-226.1,167.4-432,334.4-592.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1989.2,373.4c1.5-1.4,2.9-2.8,4.4-4.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1779.7,529.3c-1.7,1-3.5,2-5.2,3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1762.8,538.5c-47.2,24.3-99.5,37.4-152.7,38.2c-55.5,0.8-110.3-11.7-159.8-36.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1444.5,537.3c-1.8-0.9-3.5-1.9-5.3-2.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1828,461.7c0.4-2,0.8-3.9,1.1-5.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1831,442.7c1.6-14.7,1.6-29.5,0-44.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1830.1,391.9c-0.3-2-0.6-4-0.9-5.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3270.4,1041.8c-0.9,1.8-1.8,3.6-2.8,5.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3261.6,1058.2c-27.1,47.9-62.3,90.7-104.1,126.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3152.6,1188.8c-1.5,1.3-3.1,2.6-4.6,3.8"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M457,1414.8c1.8-0.9,3.6-1.8,5.4-2.7"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M474.1,1406.1c103.4-53.6,195.3-126.9,270.6-215.9c76.7-90.7,134.5-195.7,170-309"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M916.7,874.9c0.6-1.9,1.2-3.8,1.7-5.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3119.6,1271.1c0.7,1.9,1.4,3.7,2,5.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M3125.3,1289.2c5.4,22.2,5.5,45.5,0.3,67.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M3124,1363.3c-0.5,1.9-1.1,3.8-1.7,5.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2152.4,537.4c1.4,1.5,2.7,2.9,4.1,4.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2165.4,551.4c97.5,108.4,161.7,242.8,184.8,387c23.4,146.4,3.2,296.4-57.8,431.3"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2289.7,1375.7c-0.8,1.8-1.7,3.6-2.5,5.4"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1594.5,655.8c-1.9-0.7-3.7-1.5-5.6-2.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M1577,648.7c-51.4-22.3-98.4-53.6-138.8-92.4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1433.6,551.7c-1.4-1.4-2.8-2.8-4.2-4.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2803.2,1969.2c-1.6,1.2-3.3,2.3-4.9,3.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        d="M2787.6,1980.3c-163.8,114.3-348.9,194.8-544.3,236.6"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2236.9,2218.2c-2,0.4-3.9,0.8-5.9,1.2"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="2247.7"
                        y1="748.3"
                        x2="2249.7"
                        y2="742.6"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.4874,13.528',
                        }}
                        x1="2254.4"
                        y1="729.9"
                        x2="2314.2"
                        y2="565.1"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="2316.5"
                        y1="558.8"
                        x2="2318.6"
                        y2="553.1"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M2135.2,2198.8c-1.5-1.3-3-2.6-4.5-4"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '12.0071,13.0077',
                        }}
                        d="M2121,2186.2c-88.4-79.6-163.8-172.5-223.5-275.5c-60.8-104.8-104.4-218.7-129.3-337.2"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1766.8,1567.1c-0.4-2-0.8-3.9-1.2-5.9"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1517.6,398.6c1.8,0.8,3.6,1.7,5.5,2.5"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.9975,12.9973',
                        }}
                        d="M1535.1,406c37.1,13.9,77.1,18.6,116.5,13.7c41.6-5.2,81.2-21,114.8-45.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1771.6,369.9c1.6-1.2,3.1-2.5,4.7-3.7"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3660.7"
                        y1="1157"
                        x2="3656.9"
                        y2="1161.7"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '11.0774,12.0006',
                        }}
                        x1="3649.3"
                        y1="1170.9"
                        x2="3579.7"
                        y2="1255.4"
                      />
                      <line
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        x1="3575.9"
                        y1="1260"
                        x2="3572.1"
                        y2="1264.6"
                      />
                    </g>
                  </g>
                  <g style={{ opacity: 0.6 }}>
                    <g>
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1272.4,778.1c1.2-1.6,2.4-3.2,3.6-4.8"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                          strokeDasharray: '13.3929,14.509',
                        }}
                        d="M1283.6,760.9c3.3-6,6.1-12.3,8.4-18.9"
                      />
                      <path
                        style={{
                          fill: 'none',
                          stroke: '#FFFFFF',
                          strokeWidth: 2.9,
                        }}
                        d="M1294.2,735.2c0.5-1.9,1-3.9,1.5-5.8"
                      />
                    </g>
                  </g>
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1778.5,2516.3 1765,2518.5 1773.5,2503.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1526.4,2430 1536.4,2420.6 1537.7,2437.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1973.3,2274.2 1979.9,2286.3 1962.9,2283.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="914.2,1061.4 918.5,1074.4 902.4,1068.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="956.1,1131.4 954.5,1117.8 969.1,1127.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1438.4,2218.3 1425.7,2223.5 1430.6,2207 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1810.4,2492.6 1799.5,2484.1 1816.4,2480.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1794.8,2499.1 1781.8,2494.8 1796.3,2485.5 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2125.5,2400.4 2125.9,2386.7 2139,2398 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1865.2,2221.5 1874,2211 1877.3,2227.9 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1870.6,2533.8 1879.9,2523.8 1882.3,2540.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1870.6,2547.5 1882.3,2540.4 1880,2557.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1435.8,2425.4 1423.7,2431.9 1426.8,2415 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1473.4,2186.4 1462.9,2177.6 1479.8,2174.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1834.1,2492.9 1828.4,2480.4 1845.1,2484.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2213.3,2259.4 2227,2258.3 2217.2,2272.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2219.7,2216.6 2227.3,2205.2 2232.5,2221.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2217.1,2252.8 2230.5,2249.6 2223,2265.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2221,2221.4 2229.7,2210.8 2233.1,2227.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2966.1,1288.6 2968.4,1275.1 2979.8,1288 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2910.9,1359.6 2913.6,1373.1 2898.3,1365.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2710.4,1427.4 2700.1,1436.4 2699.5,1419.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2947,1362.2 2958.7,1369.4 2942.4,1375.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2371.5,1238.1 2357.9,1240.4 2366.4,1225.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2790.1,1197.4 2791.9,1183.8 2803.8,1196.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2279.3,804.6 2319.8,783.3 2308.5,839.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2936.6,1273.5 2929.1,1262 2946.3,1263.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2280.1,790.2 2289.9,780.7 2291.5,797.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2868.8,1024.2 2864.8,1037.3 2855.2,1023 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2390.6,1436.6 2379.8,1445.1 2380,1427.8 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2772.8,1594.6 2775.1,1581 2786.5,1594 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2802.9,1228.9 2813.5,1220.2 2813.6,1237.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2280.2,797.2 2291.3,789.1 2290.4,806.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2959.6,1282.6 2959.1,1268.9 2972.9,1279.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2871.9,1028.2 2869.3,1041.6 2858.2,1028.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2382.1,1225.4 2368.7,1222.7 2381.9,1211.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2280.3,793.7 2290.7,784.8 2291.1,802 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2251.1,839.9 2263.4,846.1 2247.6,853.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2395.7,1303.6 2400.2,1316.6 2383.9,1310.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2761.3,1585.1 2759.2,1571.6 2774.1,1580.2 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2902,1353.6 2901.6,1367.3 2888.5,1356 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2716.2,1664.7 2724.1,1709.7 2673.8,1681.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2808.5,1435.1 2820.6,1428.6 2817.3,1445.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2797.8,1250 2811.2,1247.2 2803.3,1262.5 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2809.3,1424 2819.7,1415 2820.3,1432.2 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2805.1,1445.9 2818.3,1442.4 2811.3,1458.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2712,1413.3 2699.5,1419 2703.8,1402.3 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2945.8,974.8 2950.4,961.8 2959.4,976.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2760.1,1274.1 2794.1,1304.8 2737.3,1313.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2760.8,1475.1 2790.3,1510.1 2732.7,1511.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2367.2,1272.9 2359.3,1284.1 2354.7,1267.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2702.5,1653.1 2699.5,1666.5 2688.8,1652.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1258,1486.7 1270.6,1492.2 1255.3,1500.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1462.3,1459.1 1455.5,1471 1449.3,1454.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="787.6,1406.4 774.4,1402.6 788.5,1392.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1487.2,1396.6 1474.8,1390.7 1490.4,1383.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1558,1440.7 1568.6,1431.9 1568.8,1449.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="460.6,1425 469.1,1414.2 472.9,1431 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="401.5,2137.7 414.7,2133.9 408.1,2149.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1265.7,1481.8 1279.1,1484.8 1265.5,1495.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="625.9,2152.6 639.4,2154.8 626.6,2166.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1511.2,1391.9 1484.3,1354.8 1541.8,1357.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1075.7,2073.6 1066.8,2063.1 1084.1,2062.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1464.3,1419 1450.9,1422 1458.6,1406.5 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="444.9,1470.6 458.4,1473.4 445,1484.3 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1275.1,1905.2 1288.4,1901.7 1281.4,1917.4 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1254,1488.6 1266,1495.1 1250.1,1501.7 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="748.8,2504.2 761.5,2499 756.6,2515.5 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1466.6,1415 1453,1416.6 1462.2,1402 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1398.3,1661.1 1407,1650.6 1410.5,1667.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1462.5,1423.1 1449.4,1427.3 1455.7,1411.2 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="817.4,1398.3 809.7,1387 826.9,1388.4 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1460,1431.6 1447.8,1438 1451.2,1421 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="831,2164.3 841.6,2173 824.7,2176.5 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1219.2,1395.9 1207.6,1388.7 1223.9,1383.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1135.7,1014.3 1145.3,1004.5 1147.3,1021.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="849.7,2155.7 863,2158.8 849.4,2169.4 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="945.2,1693.5 942.1,1706.8 931.5,1693.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2156.9,2732.3 2145.8,2740.2 2146.8,2723 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1463,1460.7 1456.5,1472.8 1449.8,1456.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1540.6,1403.6 1541,1389.9 1554.1,1401.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1046.9,1160.9 1034.6,1167 1038.3,1150.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1481.1,1400 1468.1,1395.9 1482.4,1386.4 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="335.7,2081 322.8,2076.3 337.6,2067.4 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1463.5,1461.9 1457.4,1474.2 1450.2,1458.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1099.2,1125.9 1092.5,1113.9 1109.5,1116.8 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="452,1406.9 455.1,1393.6 465.7,1407.1 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="436.9,1475.9 449.5,1481.4 434.1,1489.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1246.9,1490.8 1257.8,1499.2 1241,1503.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1204.6,1403.9 1191.2,1400.9 1204.7,1390.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1196.8,1473.9 1193.9,1487.4 1183.1,1473.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1037.8,2095.3 1024.2,2097.1 1033.3,2082.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1464.2,1463.3 1458.5,1475.8 1450.8,1460.3 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="859.5,1443 870,1434.2 870.3,1451.4 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="866.3,2109.7 874.9,2099 878.6,2115.9 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1344,702.9 1357.7,702.8 1347,716.3 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="759.7,856.8 771.3,864 755.1,869.7 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1262.7,677.4 1252.5,686.7 1251.5,669.4 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1047.9,815.7 1037.2,824.3 1037.2,807 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1992.3,312.9 1979.1,316.6 1985.9,300.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1249.9,860.1 1259.7,869.8 1242.5,871.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1434.8,516.7 1447,510.3 1443.6,527.2 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1274.7,705.8 1272.8,719.4 1261,706.7 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2070.3,359.2 2084,359 2073.4,372.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1421.6,474.5 1422.8,460.9 1435.2,472.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1346.1,815.3 1335.4,823.9 1335.5,806.6 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1485.1,719.3 1496.4,727 1479.9,732.1 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1653.4,715.4 1665.3,722.3 1649.2,728.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2065.8,364.1 2079.4,365.9 2066.9,377.8 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2079,341 2091.3,334.9 2087.5,351.8 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1663.2,710.2 1676.4,714 1662.3,723.9 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1675.2,702.6 1684.4,692.5 1687.1,709.5 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="754.7,858.3 765.5,866.7 748.7,870.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2001.1,362.2 1999.2,375.8 1987.4,363.3 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1430.2,529.5 1443.6,527 1435.4,542.2 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1646.9,717.4 1657.6,725.9 1640.8,729.6 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="921.4,859.3 926.6,872 910.1,867.1 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2079.2,321.9 2088,311.4 2091.4,328.3 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1403.1,551.4 1414.4,559.1 1397.8,564.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1422.1,540.1 1435.8,541.7 1423.5,553.8 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1341.9,643.9 1343.9,630.3 1355.6,643 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1298,719.3 1304,731.7 1287.2,727.8 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3131.7,1384.9 3130.6,1371.3 3144.8,1381 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3306.1,1671.9 3317.1,1663.7 3316.5,1680.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3307.3,1515.7 3316.4,1505.4 3319.3,1522.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3582.5,1302.1 3591.5,1291.7 3594.6,1308.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3145.7,1452.4 3159.3,1450.8 3150,1465.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3497.4,1277.6 3483.7,1276.8 3495.4,1264.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3388.7,1821.7 3383.9,1834.6 3375.1,1819.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3579.9,1292.9 3586.6,1280.9 3593,1297 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3518.7,1264 3507.2,1256.5 3523.7,1251.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3412.7,1211.3 3420.9,1200.3 3425.1,1217.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3426.6,1844.2 3434.9,1855.1 3417.6,1854.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3110.4,1474.9 3120.4,1484.3 3103.3,1486.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3307.9,1527.5 3319.2,1519.7 3317.9,1536.9 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3214.7,1670.3 3204,1678.9 3204,1661.7 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3595.5,793.7 3606.9,801.2 3590.5,806.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3517.8,1357.9 3522.5,1370.8 3506.2,1365.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3536.6,1360.5 3545.8,1370.7 3528.6,1371.6 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3421.1,1753.2 3411,1744 3428.1,1741.4 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3308,1522.8 3318.5,1513.9 3318.9,1531.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3583.3,1313.3 3594.3,1305.1 3593.6,1322.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3446.4,1756.5 3442.7,1743.3 3458.5,1750.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2893.4,1704.4 2892.7,1718.1 2879.8,1706.6 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3147.6,1402.6 3152.9,1390 3161,1405.2 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3093.6,1474.3 3099.9,1486.5 3083,1482.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3403.7,1251.4 3417.5,1251.5 3406.5,1264.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3139.8,1391.9 3141.7,1378.3 3153.5,1390.8 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3153.4,1425.9 3164,1417.3 3164,1434.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3151.7,1413.1 3159.8,1402 3164.2,1418.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3757.2,865 3761.6,852 3770.9,866.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3119.1,1378.8 3114.2,1366 3130.6,1371.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3259.2,1573.5 3267.9,1584.1 3250.7,1584.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3565.3,1272.9 3565.4,1259.2 3578.8,1270.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3288,1047.1 3295.5,1058.6 3278.3,1056.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3408.6,1399.8 3412.4,1386.6 3422.3,1400.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3150.2,1227 3160.7,1218.2 3161,1235.4 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3322.7,1037.7 3336,1041.2 3322.1,1051.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3370.2,1472 3378.6,1482.9 3361.4,1482.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3909.5,890.5 3920.2,881.9 3920.2,899.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3329.5,1031.7 3343.2,1032.6 3331.4,1045.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3416.7,1421.1 3426.4,1411.3 3428.3,1428.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3976.1,909.9 3970.1,922.2 3962.8,906.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3342.8,998.4 3353.6,989.8 3353.4,1007.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3374.6,1471.9 3384,1481.9 3366.8,1483.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3735.5,1124.1 3746.6,1116 3745.7,1133.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3692.2,1167.4 3701.4,1177.6 3684.1,1178.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3107.1,998.7 3117.7,990.1 3117.8,1007.3 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="4144.5,932.1 4153.3,942.7 4136,942.9 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3381.6,1470.8 3392.4,1479.3 3375.6,1483.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3734.7,1112.8 3743.4,1102.3 3746.8,1119.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3147.2,1244.1 3160.3,1239.8 3154.2,1255.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3821.2,907.3 3814.5,919.3 3808.2,903.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3139.9,1199.1 3142.8,1185.7 3153.6,1199.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="4063.2,889 4073.6,880 4074.2,897.3 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3101.7,1841.3 3103.7,1854.9 3088.9,1846.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3152.6,1765.4 3152.1,1751.7 3165.9,1762.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3107.3,1844.2 3111.1,1857.4 3095.2,1850.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3091.9,1833.5 3090.1,1847.1 3078.2,1834.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2051.2,1266.2 2064.3,1270.1 2050.1,1279.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2230.2,1396.9 2216.8,1394.2 2230.2,1383.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1814,1248.7 1827.2,1245.1 1820.3,1260.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2079.3,1613.7 2091.1,1620.7 2074.9,1626.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1969.5,1483.7 1975.7,1495.9 1958.8,1492.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1939.3,1406.5 1925.6,1407.4 1935.6,1393.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2028.3,1444.2 2040.3,1437.6 2037.2,1454.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1995.6,1482 2007.3,1489.3 1990.9,1494.9 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2023.5,1458 2037,1455.3 2029,1470.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2256.1,1484.4 2263.5,1496 2246.3,1494.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1934.3,1415.3 1921,1418.9 1928,1403.1 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2015.8,1401.5 2017.7,1388 2029.5,1400.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2041.1,794.3 2076.8,765.6 2076.6,823.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2022.9,757.6 2022.4,743.9 2036.2,754.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1946.8,1472.4 1946.2,1486.1 1933.3,1474.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2025.4,1274.3 2034.1,1284.9 2016.9,1285.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3199.7,792.1 3205.5,804.6 3188.8,800.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2213,1425.7 2200.9,1432.2 2204.2,1415.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1947.3,1397.8 1933.8,1395.3 1946.9,1384.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1974.4,834.8 1977.2,848.3 1961.8,840.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1939,1463.5 1935.1,1476.7 1925.3,1462.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2032.2,821.3 2045.9,820.6 2035.7,834.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1922.1,1268.5 1935,1273.2 1920.2,1282.1 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2229.7,1397.4 2216.3,1394.8 2229.5,1383.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1931,1425.8 1918.9,1432.2 1922.2,1415.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2282.7,1390.5 2279.5,1377.2 2295.1,1384.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2001.5,1267.8 2003.3,1281.4 1988.6,1272.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1982,1243.1 1974.6,1254.7 1969.1,1238.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2105.6,1588.9 2118.8,1585.2 2111.9,1601 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1763.7,1551.1 1774.2,1559.9 1757.3,1563.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2720,2362.5 2729.3,2352.4 2731.9,2369.4 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3620.7,1831.4 3620.1,1845.1 3607.2,1833.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="4009.1,1247.9 4019.4,1238.9 4020.1,1256.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3399,2117 3405.7,2105.1 3412.1,2121.1 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="4135.9,1271.4 4133.4,1284.9 4122.2,1271.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3355.5,2086 3347.4,2074.9 3364.6,2075.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3864.1,1777.4 3857.5,1789.4 3850.9,1773.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="4161.6,1286.6 4167.9,1298.8 4151,1295.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2717.6,2353.4 2724.6,2341.6 2730.6,2357.7 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3770.4,1584 3769.4,1597.7 3756.8,1585.9 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="3316.2,2168.5 3314,2182 3302.5,2169.2 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3324.1,2175.6 3324.8,2189.3 3310.8,2179.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3885.8,1718.9 3873.3,1713.2 3888.8,1705.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="4167.1,1287.4 4174.8,1298.7 4157.6,1297.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2821.1,2180.9 2827.7,2192.9 2810.8,2189.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="3157.8,2173.9 3158.9,2187.6 3144.7,2177.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2789.1,2157.7 2783.9,2170.4 2775.6,2155.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2138.6,546.2 2150.6,552.8 2134.6,559.3 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2485.4,331.3 2496.1,322.7 2496,340 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2404.5,360.5 2401.8,374 2390.8,360.7 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2411.1,295.5 2397.7,292.5 2411.2,281.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2444.7,376.7 2454.5,386.4 2437.4,388.3 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1773.8,503.1 1762.5,510.9 1763.8,493.7 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2312,455.2 2298.9,451 2313.3,441.6 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2376.6,528.9 2390.3,529.3 2379.1,542.4 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2442.6,376.9 2452,386.9 2434.7,388.1 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1860.8,350.6 1874.2,347.5 1866.7,363 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2080.7,524.9 2075.2,537.5 2067.2,522.1 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2308,535.1 2308.1,548.8 2294.6,538 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2118.8,678.5 2127.3,689.3 2110,689 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2161.9,603.4 2166.6,590.6 2175.5,605.3 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2275.3,330 2286,321.5 2285.9,338.7 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2654,363.3 2667.7,364.5 2655.7,376.9 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2426.7,375.1 2431.9,387.8 2415.4,382.9 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1850.9,297.4 1851.9,283.7 1864.5,295.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2316.9,540.8 2319.9,554.2 2304.5,546.5 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1810.8,461.7 1800.3,452.9 1817.3,449.6 	"
                  />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2300.3,465.9 2286.6,466 2297.3,452.5 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="1823.4,552.2 1833,562 1815.8,563.6 	" />
                  <polyline style={{ opacity: 0.8, fill: '#FFFFFF' }} points="2309.2,240 2308.6,253.7 2295.7,242.2 	" />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1827.4,375.8 1837.7,384.8 1820.7,387.8 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2322.2,543.1 2326.7,556.1 2310.5,550.2 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="2400.7,355.3 2396.2,368.2 2387.1,353.6 	"
                  />
                  <polyline
                    style={{ opacity: 0.8, fill: '#FFFFFF' }}
                    points="1784.2,359.7 1781.4,373.1 1770.5,359.7 	"
                  />
                </g>
                {language === 'en' ? (
                  <SvgNodesEn handleClick={handleClick} />
                ) : (
                  <SvgNodesNl handleClick={handleClick} />
                )}
                <g>
                  <path
                    d="M1365.8,519.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V519.9z"
                  />
                  <path
                    d="M1373.1,520c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V520z"
                  />
                  <path
                    d="M1373.8,523.6c0-1.3,1-1.8,2.4-2.1l1.5-0.3v-0.3c0-0.7-0.4-1.1-1.3-1.1c-0.8,0-1.3,0.4-1.5,1.1l-1-0.3
				c0.3-1,1.2-1.8,2.5-1.8c1.4,0,2.3,0.7,2.3,2.1v3.1c0,0.4,0.3,0.5,0.7,0.5v0.9c-1,0.1-1.5-0.1-1.7-0.7c-0.4,0.5-1.1,0.8-2,0.8
				C1374.7,525.4,1373.8,524.7,1373.8,523.6z M1377.8,522l-1.2,0.3c-1,0.2-1.6,0.5-1.6,1.2c0,0.6,0.5,1,1.1,1c0.9,0,1.7-0.6,1.7-1.4
				V522z"
                  />
                  <path
                    d="M1380.7,518.9h1.1v0.9c0.4-0.5,1-1,1.9-1c1.3,0,2.1,0.9,2.1,2.3v4.2h-1.1v-3.9c0-0.9-0.4-1.6-1.3-1.6
				c-0.8,0-1.6,0.6-1.6,1.7v3.8h-1.1V518.9z"
                  />
                  <path
                    d="M1387.7,523.3c0.3,0.7,1.1,1.2,1.9,1.2c0.7,0,1.4-0.3,1.4-1c0-0.7-0.6-0.8-1.7-1c-1-0.2-2.1-0.5-2.1-1.8
				c0-1.1,1-1.9,2.3-1.9c1.1,0,2.1,0.6,2.5,1.3l-0.8,0.6c-0.3-0.6-0.9-1-1.7-1c-0.7,0-1.2,0.4-1.2,0.9c0,0.6,0.6,0.7,1.4,0.9
				c1.1,0.3,2.3,0.5,2.3,1.9c0,1.2-1.1,2-2.5,2c-1.2,0-2.3-0.5-2.8-1.5L1387.7,523.3z"
                  />
                  <path
                    d="M1394.6,524.4v3.1h-1.1v-8.5h1.1v0.9c0.4-0.6,1.1-1,2-1c1.8,0,2.8,1.5,2.8,3.3c0,1.8-1,3.3-2.8,3.3
				C1395.7,525.4,1395,525,1394.6,524.4z M1394.6,522.4c0,1.5,0.9,2.1,1.8,2.1c1.2,0,1.9-1,1.9-2.3c0-1.4-0.7-2.3-1.9-2.3
				c-0.9,0-1.8,0.6-1.8,2.1V522.4z"
                  />
                  <path
                    d="M1403.2,525.4c-1.7,0-3-1.3-3-3.3c0-2,1.2-3.3,3-3.3c1.7,0,3,1.3,3,3.3C1406.2,524.1,1405,525.4,1403.2,525.4z
				 M1403.2,524.5c1.1,0,1.8-0.9,1.8-2.4c0-1.5-0.7-2.4-1.8-2.4c-1.1,0-1.8,0.9-1.8,2.4C1401.4,523.6,1402.1,524.5,1403.2,524.5z"
                  />
                  <path
                    d="M1410.9,520c-0.2,0-0.3,0-0.5,0c-0.9,0-1.7,0.7-1.7,1.8v3.5h-1.1v-6.4h1.1v1.1c0.3-0.6,0.9-1.1,1.8-1.1
				c0.2,0,0.3,0,0.4,0V520z"
                  />
                  <path
                    d="M1412.2,519.9h-1v-0.9h1v-1.9h1.1v1.9h1.4v0.9h-1.4v3.7c0,0.7,0.3,0.8,0.9,0.8c0.3,0,0.4,0,0.7-0.1v0.9
				c-0.3,0.1-0.6,0.1-1,0.1c-1.1,0-1.7-0.4-1.7-1.6V519.9z"
                  />
                </g>
              </g>
            </svg>
            <div className="controls">
              <button
                type="button"
                className="btn btn-zoom btn-up"
                onClick={() => {
                  zoom.scale({ scaleX: 1.2, scaleY: 1.2 });
                }}
              >
                +
              </button>
              <button
                type="button"
                className="btn btn-zoom btn-bottom btn-down"
                onClick={() => zoom.scale({ scaleX: 0.8, scaleY: 0.8 })}
              >
                -
              </button>
              <button type="button" className="btn btn-lg" onClick={zoom.center}>
                Center
              </button>
              {/* <button type="button" className="btn btn-lg" onClick={zoom.reset}>
                Reset
              </button> */}
              <button type="button" className="btn btn-lg" onClick={zoom.clear}>
                Clear
              </button>
            </div>
          </div>
        )}
      </Zoom>
    </div>
  );
}

const SystemMap = () => {
  const [selected, setSelected] = useState(null);
  const language = useLocaleStore((state) => state.language);
  useEffect(() => {
    // var nodes = document.querySelectorAll("#nodes g");
    // nodes.forEach((g) => {
    //   const linkedNodes = links.filter((d) => {
    //     return d.kumu_node_id === g.id;
    //   });
    //   if (linkedNodes[0] && linkedNodes[0].related_indicator1_id) {
    //     g.classList.add("has-links");
    //   }
    // });

    links.forEach((d) => {
      if (d.related_indicator1_id) {
        const id = d.kumu_node_id;
        document.getElementById(id).classList.add('has-links');
      }
    });
  });

  return (
    <div className="system-map-container">
      <SvgComponent setSelected={setSelected} language={language} />
      <img
        src={
          language === 'en'
            ? isMobileWithTablet
              ? mapLegendMobile
              : mapLegend
            : isMobileWithTablet
            ? mapLegendMobileNl
            : mapLegendNl
        }
        alt="Legend"
        style={{
          width: isMobileWithTablet ? '90%' : '15%',
          top: isMobileWithTablet ? 10 : 100,
          left: isMobileWithTablet ? 10 : 30,
          position: 'absolute',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      />
      {selected && (
        <div className="system-map-selected">
          <div className="system-map-selected-title">{getTranslation({ key: 'node_linked', language })}</div>
          {selected.map((s, i) => {
            return (
              <div className="system-map-selected-link" key={`link-1-${i}`}>
                {s.related_indicator1_name_en && (
                  <div className="mt-2">
                    <a href={`/indicators/${s.related_indicator1_id}`}>
                      {language === 'en' ? s.related_indicator1_name_en : s.related_indicator1_name_nl}
                    </a>
                  </div>
                )}
                {s.related_indicator2_name_en && (
                  <div className="mt-2">
                    <a href={`/indicators/${s.related_indicator2_id}`}>
                      {language === 'en' ? s.related_indicator2_name_en : s.related_indicator2_name_nl}
                    </a>
                  </div>
                )}
                {s.related_indicator3_name_en && (
                  <div className="mt-2">
                    <a href={`/indicators/${s.related_indicator3_id}`}>
                      {language === 'en' ? s.related_indicator3_name_en : s.related_indicator3_name_nl}
                    </a>
                  </div>
                )}
                {s.related_indicator4_name_en && (
                  <div className="mt-2">
                    <a href={`/indicators/${s.related_indicator4_id}`}>
                      {language === 'en' ? s.related_indicator4_name_en : s.related_indicator4_name_nl}
                    </a>
                  </div>
                )}
              </div>
            );
          })}
          <a href="#close" className="close" onClick={() => setSelected(null)}>
            &times;
          </a>
        </div>
      )}
    </div>
  );
};

export default SystemMap;

import { Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import map from '../assets/images/map.png';
import Cards from '../components/Cards';
import GeoMap from '../components/GeoMap';
import IndicatorsWrapper from '../components/IndicatorsWrapper';
import LinkScrollTop from '../components/LinkScrollTop';
import RandomSentence from '../components/RandomSentence';
import Statistics from '../components/Statistics';
import SystemMap from '../components/SystemMap';
import { getTranslation } from '../dictionary';
import { aboutQueryOptions } from '../services/AboutService';
import { useLocaleStore } from '../store';
import { isMobileWithTablet } from '../utils';

export default function Home() {
  const language = useLocaleStore((state) => state.language);
  const [mapType, setMapType] = useState('geo');
  const [mapFilter, setFilter] = useState(null);
  const [categorySelected, setCategorySelected] = useState('experts');

  const { isLoading, data: about } = useQuery(aboutQueryOptions);

  return (
    <div className="Home">
      <section className="intro-sentence">
        <div className="container-fluid">
          <div className="row mb-5">
            <div className="col-12">
              <div className="font-strong-xl upper">
                {!isLoading ? <RandomSentence /> : <Skeleton variant="rounded" height={200} className="mb-2" />}
              </div>
            </div>
          </div>
          <div className="border-2 border-bottom border-secondary shadow shadow-primary shadow-intensity-lg"></div>

          <div className="pt-5">
            {language === 'nl' && (
              <div className="color-black font-serif-l fw-bold display-3">
                <h2 className="mt-4 mb-1">Wat is de EcoFoodMap?</h2>
                {
                  <section className="mb-3">
                    {!isLoading ? (
                      about.Content.split('\n')[0]
                    ) : (
                      <div>
                        <Skeleton variant="rounded" animation="wave" height={120} className="my-2" />
                      </div>
                    )}
                  </section>
                }
              </div>
            )}
            {language === 'en' && (
              <div className="color-black font-serif-l fw-bold display-3">
                <h2 className="mt-4 mb-1">What is the EcoFoodMap?</h2>
                {<section className="mb-3">{!isLoading && about.Content_en.split('\n')[0]}</section>}
              </div>
            )}

            <div className="my-space-2">
              <LinkScrollTop className="button l rounded-3" to={`/about`}>
                {getTranslation({ key: 'learn_more', language })}
              </LinkScrollTop>
            </div>
          </div>
        </div>
      </section>
      <section className="geographic-context">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-4 d-none d-xl-block">
              <img className="img-fluid" src={map} alt="Fluid" />
            </div>
            <div className="col-lg-6 col-xl-8">
              <Statistics />
            </div>
          </div>
        </div>
      </section>

      <section className="cards-wrapper pad">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h2 className="">{getTranslation({ key: 'indicators_dashboard', language })}</h2>
            </div>
          </div>
        </div>
        <IndicatorsWrapper />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 my-space-2 text-center">
              <LinkScrollTop className="button l rounded-3" to={`/indicators`}>
                {getTranslation({ key: 'see_all', language })}
              </LinkScrollTop>
            </div>
          </div>
        </div>
      </section>

      {isMobileWithTablet && (
        <div className="ui">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 d-flex align-items-center mb-5">
                <h2 className="mb-0">{getTranslation({ key: 'mapping_food_system', language })}</h2>
              </div>
              <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-4">
                <a
                  role="button"
                  href="#mapping-viz-wrapper"
                  className={`button selector ${mapType === 'geo' ? 'active' : ''}`}
                  onClick={() => setMapType('geo')}
                >
                  {getTranslation({ key: 'geo_map', language })}
                </a>
                <a
                  role="button"
                  href="#mapping-viz-wrapper"
                  className={`button selector ${mapType === 'system' ? 'active' : ''}`}
                  onClick={() => setMapType('system')}
                >
                  {getTranslation({ key: 'sys_map', language })}
                </a>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className="mapping">
        {mapType === 'geo' && (
          <div
            style={{
              width: isMobileWithTablet ? '10%' : '15%',
              top: isMobileWithTablet ? 10 : 100,
              left: isMobileWithTablet ? 10 : 30,
              position: 'absolute',
              zIndex: 3,
            }}
          >
            <svg>
              <circle style={{ fill: '#5100ff' }} cx="10" cy="10" r="5" />
              <circle style={{ fill: '#ff9c50' }} cx="10" cy="40" r="5" />
              <circle style={{ fill: '#ff6fa7' }} cx="10" cy="70" r="5" />
              <text
                className={`selectable-filter${mapFilter === 'stakeholders' ? ' selected' : ''}`}
                x="30"
                y="15"
                onClick={() => setFilter((state) => (state === 'stakeholders' ? null : 'stakeholders'))}
              >
                {getTranslation({ key: 'stakeholders', language })}
              </text>
              <text
                className={`selectable-filter${mapFilter === 'initiatives' ? ' selected' : ''}`}
                x="30"
                y="45"
                onClick={() => setFilter((state) => (state === 'initiatives' ? null : 'initiatives'))}
              >
                {getTranslation({ key: 'initiatives', language })}
              </text>
              <text
                className={`selectable-filter${mapFilter === 'experts' ? ' selected' : ''}`}
                x="30"
                y="75"
                onClick={() => setFilter((state) => (state === 'experts' ? null : 'experts'))}
              >
                {getTranslation({ key: 'experts', language })}
              </text>

              <text className="label explanation" x="30" y="105">
                {getTranslation({ key: 'explanation', language })}
              </text>
            </svg>
          </div>
        )}
        {!isMobileWithTablet && (
          <div className="ui pad-small pad-small-b">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 d-flex align-items-center mb-5">
                  <h2 className="color-white mb-0">{getTranslation({ key: 'mapping_food_system', language })}</h2>
                </div>
                <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-5">
                  <a
                    role="button"
                    href="#mapping-viz-wrapper"
                    className={`button white selector ${mapType === 'geo' ? 'active' : ''}`}
                    onClick={() => setMapType('geo')}
                  >
                    {getTranslation({ key: 'geo_map', language })}
                  </a>
                  <a
                    role="button"
                    href="#mapping-viz-wrapper"
                    className={`button white selector ${mapType === 'system' ? 'active' : ''}`}
                    onClick={() => setMapType('system')}
                  >
                    {getTranslation({ key: 'sys_map', language })}
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="mapping-viz-wrapper">
          {mapType === 'geo' && <GeoMap mapFilter={mapFilter} className="h-100 w-100" />}
          {mapType === 'system' && <SystemMap />}
        </div>
      </section>

      <section className="stakeholders-cards pad">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 d-flex align-items-center mb-5">
              <h2 className="mb-0">{getTranslation({ key: 'food_ecosystem', language })}</h2>
            </div>
            <div className="col-lg-6 d-flex align-items-center justify-content-lg-end mb-5">
              <div>
                <a
                  role="button"
                  href="#mapping-viz-wrapper"
                  className={`button selector ${categorySelected === 'experts' ? 'active' : ''}`}
                  onClick={() => setCategorySelected('experts')}
                >
                  {getTranslation({ key: 'experts', language })}
                </a>
                <a
                  role="button"
                  href="#mapping-viz-wrapper"
                  className={`button selector ${categorySelected === 'stakeholders' ? 'active' : ''}`}
                  onClick={() => setCategorySelected('stakeholders')}
                >
                  {getTranslation({ key: 'stakeholders', language })}
                </a>
                <a
                  role="button"
                  href="#mapping-viz-wrapper"
                  className={`button selector ${categorySelected === 'initiatives' ? 'active' : ''}`}
                  onClick={() => setCategorySelected('initiatives')}
                >
                  {getTranslation({ key: 'initiatives', language })}
                </a>
              </div>
            </div>
          </div>
        </div>
        <Cards selected={categorySelected} />
      </section>
    </div>
  );
}

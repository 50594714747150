import TextField from '@mui/material/TextField';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { createUserExpert } from '../services/ExpertService';
import { interestsQueryOptions } from '../services/InterestService';
import { cleanData, getCoords, uploadImage } from '../utils';
import FileUpload from './FileUpload';
import InterestSelect from './InterestSelect';

const FormExpert = ({ language, setIsOpen }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [file, setFile] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState([]);

  const interests = useQuery(interestsQueryOptions);
  const queryClient = useQueryClient();

  //TODO: If one of the 3 methods fails, the expert should not be created and everything should be rolled back
  const onSubmit = async (data) => {
    console.log('Submitting expert data...');

    const [photoId, locationId] = await Promise.all([uploadImage(file), getCoords(data.address)]);
    const user = JSON.parse(sessionStorage.getItem('stytch-user'));

    const formattedData = {
      data: {
        ...data,
        admin_email: user?.emails[0].email || null,
        photo: photoId,
        interest: {
          connect: selectedInterests?.map((interestKey) => {
            const interest = interests.data.find((item) => item.name === interestKey);
            return {
              id: interest?.id,
              position: { end: true },
            };
          }),
        },
        user_id: user?.user_id || null,
        ...(locationId && {
          location: {
            connect: [
              {
                id: locationId,
              },
            ],
          },
        }),
      },
    };

    createUserExpert(cleanData(formattedData))
      .then(() => {
        queryClient.invalidateQueries('experts');
        setIsOpen(false);
        enqueueSnackbar(getTranslation({ key: 'submit_success', language }), {
          variant: 'success',
        });
        document.getElementById('userExperts').scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        enqueueSnackbar(getTranslation({ key: 'something_wrong', language }), {
          variant: 'error',
        });
        console.error('Error creating expert:', error);
      });
  };

  return (
    <div className="form-container p-0">
      <section id="form-wrapper" className="w-100 mx-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="container-fluid narrow w-100">
          <TextField
            id="name"
            name="name"
            label={getTranslation({ key: 'name', language }) + ' *'}
            variant="standard"
            error={Boolean(errors.name)}
            helperText={errors.name ? getTranslation({ key: 'name_error', language }) : ''}
            {...register('name', { required: true })}
          />

          <TextField
            id="description_nl"
            label={getTranslation({ key: 'description_nl', language }) + ' *'}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description', { required: true })}
            error={Boolean(errors.description)}
            helperText={errors.description ? getTranslation({ key: 'description_error', language }) : ''}
          />

          <TextField
            id="description_en"
            label={getTranslation({ key: 'description_en', language }) + ' *'}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description_en', { required: true })}
            error={Boolean(errors.description_en)}
            helperText={
              errors.description_en
                ? getTranslation({
                    key: 'description_en_error',
                    language,
                  })
                : ''
            }
          />

          <TextField
            id="email"
            label={getTranslation({ key: 'email_contact', language }) + ' *'}
            variant="standard"
            {...register('contact_email', {
              required: true,
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              },
            })}
            error={Boolean(errors.contact_email)}
            helperText={errors.contact_email ? getTranslation({ key: 'email_error', language }) : ''}
          />

          <TextField
            id="adress"
            label={getTranslation({
              key: 'location_address',
              language,
            })}
            placeholder={getTranslation({ key: 'address_placeholder', language })}
            variant="standard"
            {...register('address')}
          />

          <TextField
            id="website"
            label={getTranslation({ key: 'website', language })}
            variant="standard"
            {...register('website', {
              pattern: {
                value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
              },
            })}
            error={Boolean(errors.webstie)}
            helperText={
              errors.website
                ? getTranslation({
                    key: 'invalid_website_error',
                    language,
                  })
                : ''
            }
          />

          <TextField
            id="telephone"
            label={getTranslation({
              key: 'telephone_contact',
              language,
            })}
            variant="standard"
            {...register('contact_tel', {
              pattern: {
                value: /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/,
              },
            })}
            error={Boolean(errors.contact_tel)}
            helperText={errors.contact_tel ? getTranslation({ key: 'phone_number_error', language }) : ''}
          />

          <InterestSelect
            language={language}
            interests={interests}
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
            register={register}
          />

          <FileUpload file={file} setFile={setFile} />

          <button className="button rounded-3 mt-3 w-100" type="submit">
            {getTranslation({ key: 'submit', language })}
          </button>
        </form>
      </section>
    </div>
  );
};

export default FormExpert;

import { CloudUpload } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Button, FormControl, IconButton, Typography } from '@mui/material';
import React, { useId } from 'react';
import { useLocaleStore } from '../store';
import { getTranslation } from '../dictionary';

const FileUpload = ({ file, setFile }) => {
  const id = useId();
  const language = useLocaleStore((state) => state.language);

  const handleFileChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setFile(event.target.files[0]);
    }
  };

  const handleDelete = () => {
    setFile(null);
    document.getElementById(`photo-upload-${id}`).value = null;
  };

  return (
    <FormControl
      sx={{ flexDirection: 'column', width: '100%', justifyContent: 'center', alignItems: 'center', marginTop: '1em' }}
    >
      <input
        accept="image/*"
        id={`photo-upload-${id}`}
        type="file"
        name="photo"
        onChange={handleFileChange}
        style={{ display: 'none' }}
      />
      <label htmlFor={`photo-upload-${id}`}>
        <Button variant="outlined" color="primary" component="span" startIcon={<CloudUpload />}>
          Upload Photo
        </Button>
      </label>
      {file && file?.data !== null && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '1em', maxWidth: '80%' }}>
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '0.5em',
              flexShrink: 1,
            }}
          >
            {file.name}
          </Typography>
          <IconButton color="primary" onClick={handleDelete}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
      <div className="text-danger fw-bold text-center mx-auto w-100 mt-2">
        {getTranslation({ key: 'photo_upload_warning', language })}
      </div>
    </FormControl>
  );
};

export default FileUpload;

import { StytchLogin } from '@stytch/react';
import { Products } from '@stytch/vanilla-js';
import React from 'react';
import { Navigate } from 'react-router-dom';
import loginsvg from '../assets/images/login.svg';
import LinkScrollTop from '../components/LinkScrollTop';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';

/*
Login configures and renders the StytchLogin component which is a prebuilt UI component for auth powered by Stytch
This component accepts style, config, and callbacks props. To learn more about possible options review the documentation at
https://stytch.com/docs/sdks/javascript-sdk#ui-configs
*/
const Login = () => {
  const user = JSON.parse(sessionStorage.getItem('stytch-user'));

  const language = useLocaleStore((state) => state.language);

  const redirectLoginURL = `${window.location.origin}/logged-in`;
  const config = {
    products: [Products.emailMagicLinks],
    emailMagicLinksOptions: {
      loginRedirectURL: redirectLoginURL,
      loginExpirationMinutes: 60,

      signupRedirectURL: redirectLoginURL,
      signupExpirationMinutes: 60,
    },
  };

  if (user !== null) {
    return <Navigate to="/data" />;
  } else {
    return (
      <div style={{ width: '100vw' }} className="Home">
        <section className="indicators-intro pad-small">
          <div className="container-fluid">
            <div className="row pb-5">
              <div className="col-12">
                <div className="large-small-header-combo">
                  <h2>{getTranslation({ key: 'contribution', language })}</h2>
                </div>
                <p className="font-serif-l">
                  {getTranslation({ key: 'contribution_text2', language })}
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="container h-100">
          <div className=" text-black rounder-7">
            <div className="card-body p-md-5">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-5">
                  <h3 style={{ marginBottom: '1em' }}>
                    {getTranslation({ key: 'contribution_text3', language })}
                  </h3>
                  <div className="d-flex justify-content-center flex-column w-auto">
                    <div className="mx-auto w-100">
                      <StytchLogin id="stytchLogin" styles={{ container: { width: null } }} config={config} />
                    </div>
                    <div className="d-flex justify-content-center mx-auto text-center">
                      <p style={{ fontFamily: 'Tiempos' }} className="py-2 px-2">
                        {getTranslation({ key: 'privacy', language })}&nbsp;
                        <LinkScrollTop to={'/privacy'}>
                          {getTranslation({ key: 'privacy_link', language })}
                        </LinkScrollTop>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-xl-6">
                  <img className="logged-in-image" src={loginsvg} alt="loginsvg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Login;

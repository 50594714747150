//If you think, "What the f*ck is this code?", I know. I feel you. But remember, it was even worse when we started... ~ Some previous developer
import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { updateUserExpert } from '../services/ExpertService';
import { interestsQueryOptions } from '../services/InterestService';
import { useLocaleStore } from '../store';
import { cleanData, compareStates, deleteImage, getCoords, uploadImage } from '../utils';
import FileUpload from './FileUpload';
import { enqueueSnackbar } from 'notistack';

const UpdateExpert = ({ props, setIsOpen }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const language = useLocaleStore((state) => state.language);

  const { isLoading, data: interestList } = useQuery(interestsQueryOptions);
  const queryClient = useQueryClient();

  const initialState = (interestList || []).reduce((acc, interest) => {
    acc[interest.name] = props.interest ? props.interest.some((i) => i?.name === interest?.name) : false;
    return acc;
  }, {});

  const [interestState, setInterestState] = useState([]);
  const [file, setFile] = useState(props.photo);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handleChange = (event) => {
    setInterestState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  const onSubmit = async (data) => {
    console.log('Submitting expert data...');
    const interests = compareStates(initialState, interestState, interestList);
    const locationId = data.address?.trim() === props.address?.trim() ? null : await getCoords(data.address);


    if(!file && props.photo?.id){
      deleteImage(props.photo.id);
    } else if (file?.lastModified) {
      const photoId = await uploadImage(file);
      data.photo = photoId;
    }

    const updatedData = {
      ...data,
      ...(interests && { interest: interests }),
      ...(locationId && {
        location: {
          connect: [
            {
              id: locationId,
            },
          ],
        },
      }),
    };

    delete updatedData.card;

    updateUserExpert(props.id, cleanData(updatedData))
      .then(() => {
        queryClient.invalidateQueries('experts');
        setIsOpen(false);
        enqueueSnackbar(getTranslation({ key: 'submit_success', language }), {
          variant: 'success',
        });
        document.getElementById('userExperts').scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        enqueueSnackbar(getTranslation({ key: 'something_wrong', language }), {
          variant: 'error',
        });
        console.error('Error updating expert:', error);
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="name">{getTranslation({ key: 'name', language }) + '*'}</label>
        <input
          defaultValue={props.name}
          type="text"
          className="form-control"
          name="name"
          {...register('name', { required: true })}
        />
        {errors.name && <div style={{ color: 'red' }}>{getTranslation({ key: 'name_error', language })}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="description">{getTranslation({ key: 'description_nl', language }) + '*'}</label>
        <input
          type="text"
          defaultValue={props.description}
          className="form-control"
          name="description"
          {...register('description', { required: true })}
        />
        {errors.description && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'description_error', language })}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="description_en">{getTranslation({ key: 'description_en', language }) + '*'}</label>
        <input
          type="text"
          defaultValue={props.description_en}
          className="form-control"
          name="description_en"
          {...register('description_en', { required: true })}
        />
        {errors.description_en && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'description_en_error', language })}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="email">{getTranslation({ key: 'email_contact', language }) + '*'}</label>
        <input
          defaultValue={props.contact_email}
          type="text"
          className="form-control"
          name="email"
          {...register('contact_email', {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            },
          })}
        />
        {errors.contact_email && <div style={{ color: 'red' }}>{getTranslation({ key: 'email_error', language })}</div>}
      </div>

      <div className="form-group">
        <label htmlFor="website">{getTranslation({ key: 'website', language })}</label>
        <input
          defaultValue={props.website}
          type="text"
          className="form-control"
          name="website"
          {...register('website', {
            pattern: {
              value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
            },
          })}
        />
        {errors.website && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'invalid_website_error', language })}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="phone">{getTranslation({ key: 'telephone_contact', language })}</label>
        <input
          defaultValue={props.contact_tel}
          type="text"
          className="form-control"
          name="phone"
          {...register('contact_tel', {
            pattern: {
              value: /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/,
            },
          })}
        />
        {errors.contact_tel && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'phone_number_error', language })}</div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="address">{getTranslation({ key: 'address', language })}</label>
        <input
          defaultValue={props.address}
          type="text"
          placeholder={getTranslation({ key: 'address_placeholder', language })}
          className="form-control"
          name="address"
          {...register('address')}
        />
      </div>

      <FileUpload file={file} setFile={setFile} />

      <div className="row mt-4">
        <div className="col-sm-6">
          <FormLabel component="legend">{getTranslation({ key: 'interests', language })}</FormLabel>
          <FormGroup>
            {Object.keys(initialState)
              .slice(0, 8)
              .map((interest) => {
                return (
                  <FormControlLabel
                    key={interest}
                    control={
                      <Switch
                        checked={
                          interestState && interestState[interest] !== undefined
                            ? interestState[interest]
                            : initialState[interest]
                        }
                        onChange={handleChange}
                        name={interest}
                      />
                    }
                    label={getTranslation({
                      key: interest,
                      categoryKey: 'interestslist',
                      language,
                    })}
                  />
                );
              })}
          </FormGroup>
        </div>
        <div className="col-sm-6">
          <FormGroup>
            {Object.keys(initialState)
              .slice(8, initialState.length)
              .map((interest, key) => {
                return (
                  <FormControlLabel
                    key={interest}
                    control={
                      <Switch
                        checked={
                          interestState && interestState[interest] !== undefined
                            ? interestState[interest]
                            : initialState[interest]
                        }
                        onChange={handleChange}
                        name={interest}
                      />
                    }
                    label={getTranslation({
                      key: interest,
                      categoryKey: 'interestslist',
                      language,
                    })}
                  />
                );
              })}
          </FormGroup>
        </div>
      </div>

      <button type="submit" className="button rounded-3">
        Update
      </button>
    </form>
  );
};

export default UpdateExpert;

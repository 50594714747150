import React, { useState } from 'react';
import FormExpert from '../components/FormExpert';
import FormInitiative from '../components/FormInitiative';
import FormStakeholder from '../components/FormStakeholder';
import { getTranslation } from '../dictionary';
import { useLocaleStore } from '../store';

const FormCombo = () => {
  const language = useLocaleStore((state) => state.language);

  const [expertSelected, setExpertSelected] = useState(false);
  const [stakeholderSelected, setStakeholderSelected] = useState(false);
  const [initiativeSelected, setInitiativeSelected] = useState(false);

  return (
    <>
      <div className="container-fluid">
        <div className="col-12 col-md-10 col-lg-8 mx-auto mx-md-0">
          <section>
            <div className="adds card mt-5 shadow px-4 pt-4 pb-1">
              {!initiativeSelected && !stakeholderSelected && (
                <>
                  <h3
                    role="button"
                    onClick={() => {
                      setExpertSelected(!expertSelected);
                    }}
                    className="pb-1"
                  >
                    {getTranslation({ key: 'add_expert', language })} {!expertSelected && <>&#9660;</>}
                    {expertSelected && <>&#9650;</>}
                  </h3>
                  <div className="font-serif-m font-400 pb-3">
                    {getTranslation({
                      key: 'application_expert',
                      language,
                    })}
                  </div>
                </>
              )}

              {expertSelected && (
                <div className="row mx-auto">
                  <FormExpert language={language} setIsOpen={setExpertSelected} />
                </div>
              )}

              {!expertSelected && !stakeholderSelected && (
                <>
                  <h3
                    role="button"
                    onClick={() => {
                      setInitiativeSelected(!initiativeSelected);
                    }}
                    className="pb-1"
                  >
                    {getTranslation({ key: 'add_initiative', language })} {!initiativeSelected && <>&#9660;</>}
                    {initiativeSelected && <>&#9650;</>}
                  </h3>
                  <div className="font-serif-m font-400 pb-3">
                    {getTranslation({
                      key: 'application_initiative',
                      language,
                    })}
                  </div>
                </>
              )}

              {initiativeSelected && (
                <div className="row mx-auto">
                  <FormInitiative language={language} setIsOpen={setInitiativeSelected} />
                </div>
              )}

              {!expertSelected && !initiativeSelected && (
                <>
                  <h3
                    role="button"
                    onClick={() => {
                      setStakeholderSelected(!stakeholderSelected);
                    }}
                    className="pb-1"
                  >
                    {getTranslation({ key: 'add_stakeholder', language })} {!stakeholderSelected && <>&#9660;</>}
                    {stakeholderSelected && <>&#9650;</>}
                  </h3>
                  <div className="font-serif-m font-400 pb-3">
                    {getTranslation({
                      key: 'application_stakeholder',
                      language,
                    })}
                  </div>
                </>
              )}

              {stakeholderSelected && (
                <div className="row mx-auto">
                  <FormStakeholder language={language} setIsOpen={setStakeholderSelected} />
                </div>
              )}
              <div className="text-danger fw-bold text-center mx-auto w-100 mt-2">
                {getTranslation({ key: 'data_visibility', language })}
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default FormCombo;

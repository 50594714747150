import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { interestsQueryOptions } from '../services/InterestService';
import { sectorsQueryOptions } from '../services/SectorService';
import { updateUserStakeholder } from '../services/StakeholderService';
import { targetsQueryOptions } from '../services/TargetService';
import { useLocaleStore } from '../store';
import { cleanData, compareStates, deleteImage, getCoords, parseOpeningHours, uploadImage } from '../utils';
import RenderSwitches from './RenderSwitches';
import FileUpload from './FileUpload';
import OpeningHoursForm from './OpeningHours';
import { enqueueSnackbar } from 'notistack';

const UpdateStakeholder = ({ props, setIsOpen }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const language = useLocaleStore((state) => state.language);

  const { isLoading: interestIsLoading, data: interestList } = useQuery(interestsQueryOptions);

  const { isLoading: sectorIsLoading, data: sectorList } = useQuery(sectorsQueryOptions);

  const { isLoading: targetIsLoading, data: targetList } = useQuery(targetsQueryOptions);

  const queryClient = useQueryClient();

  const [interestState, setInterestState] = useState([]);

  const [sectorState, setSectorState] = useState([]);

  const [targetState, setTargetState] = useState({
    b2c: props.target.some((target) => target.type === 'b2c'),
    b2b: props.target.some((target) => target.type === 'b2b'),
  });

  const [targetb2cState, setTargetb2cState] = useState([]);

  const [targetb2bState, setTargetb2bState] = useState([]);
  const [file, setFile] = useState(props.logo?.data?.attributes);

  const initializeState = (list, propItems) => {
    return list?.reduce((acc, item) => {
      acc[item.name] = propItems ? propItems.some((i) => i?.name === item.name) : false;
      return acc;
    }, {});
  };

  const interestInilialState = initializeState(interestList, props.interest);
  const sectorInilialState = initializeState(sectorList, props.sector);
  const targetInilialState = initializeState(targetList, props.target);

  const handleStateChange = (setState) => (event) => {
    const { name, checked } = event.target;
    setState((prevState) => ({ ...prevState, [name]: checked }));
  };

  const getTargetTypeList = (targetList, type) => Object.values(targetList).filter((target) => target.type === type);

  useEffect(() => {
    if (!interestList || !sectorList || !targetList) return;
    setInterestState(initializeState(interestList, props.interest));
    setSectorState(initializeState(sectorList, props.sector));
    setTargetb2cState(initializeState(getTargetTypeList(targetList, 'b2c'), props.target));
    setTargetb2bState(initializeState(getTargetTypeList(targetList, 'b2b'), props.target));
  }, [interestList, props.interest, props.sector, props.target, sectorList, targetList]);

  const openingHoursFormRef = useRef();

  if (interestIsLoading || sectorIsLoading || targetIsLoading) {
    return <div>Loading...</div>;
  }

  const propsHours = parseOpeningHours(props?.opening_hours);

  const onSubmit = async (data) => {
    const interests = compareStates(interestInilialState, interestState, interestList);
    const sectors = compareStates(sectorInilialState, sectorState, sectorList);
    const targets = compareStates(targetInilialState, { ...targetb2bState, ...targetb2cState }, targetList);
    const locationId = data.address?.trim() === props.address?.trim() ? null : await getCoords(data.address);

    if(!file && props.logo?.data?.id){
      deleteImage(props.logo.data.id);
    } else if (file?.lastModified) {
      const photoId = await uploadImage(file);
      data.logo = photoId;
    }

    const newData = {
      ...data,
      contact_email: data.contact_email?.trim() || null,
      opening_hours: openingHoursFormRef?.current?.getOpeningHours(),
      ...(interests && { interest: interests }),
      ...(sectors && { sector: sectors }),
      ...(targets && { target: targets }),
      ...(locationId && {
        location: {
          connect: [
            {
              id: locationId,
            },
          ],
        },
      }),
    };

    delete newData.card;

    updateUserStakeholder(props.id, cleanData(newData))
      .then(() => {
        queryClient.invalidateQueries('stakeholders');
        setIsOpen(false);
        enqueueSnackbar(getTranslation({ key: 'submit_success', language }), {
          variant: 'success',
        });
        document.getElementById('userStakeholders').scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        enqueueSnackbar(getTranslation({ key: 'something_wrong', language }), {
          variant: 'error',
        });
        console.error('Error updating stakeholder:', error);
      });
  };

  return (
    <form className="form" onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="name">{getTranslation({ key: 'name', language }) + '*'}</label>
        <input
          defaultValue={props.name}
          type="text"
          className="form-control"
          name="name"
          {...register('name', { required: true })}
        />
        {errors.name && <div style={{ color: 'red' }}>{getTranslation({ key: 'name_error', language })}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="description">{getTranslation({ key: 'description_nl', language }) + '*'}</label>
        <textarea
          defaultValue={props.description}
          className="form-control textarea_height"
          name="description"
          {...register('description', { required: true })}
        />
        {errors.description && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'description_error', language })}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="description_en">{getTranslation({ key: 'description_en', language }) + '*'}</label>
        <textarea
          defaultValue={props.description_en}
          className="form-control textarea_height"
          name="description_en"
          {...register('description_en', { required: true })}
        />
        {errors.description_en && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'description_en_error', language })}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="email">{getTranslation({ key: 'email_contact', language }) + '*'}</label>
        <input
          defaultValue={props.contact_email}
          type="text"
          className="form-control"
          name="email"
          {...register('contact_email', {
            required: true,
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            },
          })}
        />
        {errors.contact_email && <div style={{ color: 'red' }}>{getTranslation({ key: 'email_error', language })}</div>}
      </div>
      <div className="form-group">
        <label htmlFor="phone">{getTranslation({ key: 'telephone_contact', language })}</label>
        <input
          defaultValue={props.contact_tel}
          type="text"
          className="form-control"
          name="phone"
          {...register('contact_tel', {
            pattern: {
              value: /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/,
            },
          })}
        />
        {errors.contact_tel && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'phone_number_error', language })}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="website">{getTranslation({ key: 'website', language })}</label>
        <input
          defaultValue={props.website}
          type="text"
          className="form-control"
          name="website"
          {...register('website', {
            pattern: {
              value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
            },
          })}
        />
        {errors.website && (
          <div style={{ color: 'red' }}>{getTranslation({ key: 'invalid_website_error', language })}</div>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="address">{getTranslation({ key: 'location_address', language })}</label>
        <input
          defaultValue={props.address}
          type="text"
          placeholder={getTranslation({ key: 'address_placeholder', language })}
          className="form-control"
          name="address"
          {...register('address')}
        />
      </div>

      <div className="form-group">
        <label htmlFor="enterprise_size">{getTranslation({ key: 'enterprise_size', language })}</label>
        <input
          defaultValue={props.enterprise_size}
          type="number"
          className="form-control"
          name="enterprise_size"
          {...register('enterprise_size')}
        />
      </div>

      <OpeningHoursForm language={language} propsHours={propsHours} ref={openingHoursFormRef} />

      <FileUpload file={file} setFile={setFile} />

      <div className="row mt-4">
        <div className="col-sm-6 pb-4">
          <RenderSwitches
            state={Object.keys(interestState)
              .slice(0, 8)
              .reduce((obj, key) => {
                obj[key] = interestState[key];
                return obj;
              }, {})}
            label={getTranslation({ key: 'interests', language })}
            handleChange={handleStateChange(setInterestState)}
            control={control}
            categoryKey={'interestslist'}
          />
        </div>
        <div className="col-sm-6 pb-4">
          <RenderSwitches
            state={Object.keys(interestState)
              .slice(8, interestState.length)
              .reduce((obj, key) => {
                obj[key] = interestState[key];
                return obj;
              }, {})}
            handleChange={handleStateChange(setInterestState)}
            control={control}
            categoryKey={'interestslist'}
          />
        </div>
        <div className="col-sm-6 pb-4">
          <RenderSwitches
            state={Object.keys(sectorState)
              .slice(0, 8)
              .reduce((obj, key) => {
                obj[key] = sectorState[key];
                return obj;
              }, {})}
            label={getTranslation({ key: 'sector', language })}
            handleChange={handleStateChange(setSectorState)}
            control={control}
          />
        </div>

        <div className="col-sm-6 pb-4">
          <RenderSwitches
            state={Object.keys(sectorState)
              .slice(8, sectorState.length)
              .reduce((obj, key) => {
                obj[key] = sectorState[key];
                return obj;
              }, {})}
            handleChange={handleStateChange(setSectorState)}
            control={control}
          />
        </div>
      </div>

      <RenderSwitches
        state={targetState}
        label={getTranslation({ key: 'target', language })}
        handleChange={handleStateChange(setTargetState)}
        control={control}
      />

      <div className="row mt-4">
        {targetState.b2c && (
          <div className="col-sm-6">
            <RenderSwitches
              state={targetb2cState}
              label={getTranslation({ key: 'b2c', language })}
              handleChange={handleStateChange(setTargetb2cState)}
              control={control}
            />
          </div>
        )}

        {targetState.b2b && (
          <div className="col-sm-6">
            <RenderSwitches
              state={targetb2bState}
              label={getTranslation({ key: 'b2b', language })}
              handleChange={handleStateChange(setTargetb2bState)}
              control={control}
            />
          </div>
        )}
      </div>

      <button type="submit" className="button rounded-3">
        Update
      </button>
    </form>
  );
};

export default UpdateStakeholder;

import { useQuery } from '@tanstack/react-query';
import 'mapbox-gl/dist/mapbox-gl.css';
import React, { useCallback, useEffect, useState } from 'react';
import Map, {
  FullscreenControl,
  GeolocateControl,
  Layer,
  NavigationControl,
  Popup,
  ScaleControl,
  Source,
} from 'react-map-gl';
import { getTranslation } from '../dictionary';
import { expertsGeoMapQueryOptions } from '../services/ExpertService';
import { initiativesQueryOptions } from '../services/InitiativeService';
import { stakeholdersQueryOptions } from '../services/StakeholderService';
import { useLocaleStore } from '../store';
import { getDataSourceMap } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import { Box, Paper } from '@mui/material';

const GeoMap = ({ mapFilter }) => {
  const [dataSource, setDataSource] = useState(null);

  const { isLoading: expertsLoading, data: experts } = useQuery(expertsGeoMapQueryOptions);
  const { isLoading: stakeholdersLoading, data: stakeholders } = useQuery({
    ...stakeholdersQueryOptions,
    select: (data) => {
      return data.map((d) => ({
        type: 'stakeholder',
        location: d.location,
        name: d.name,
        interests: d.interest,
        id: d.id,
      }));
    },
  });
  const { isLoading: initiativesLoading, data: initiatives } = useQuery({
    ...initiativesQueryOptions,
    select: (data) => {
      return data.map((d) => ({
        type: 'initiative',
        location: d.location,
        name: d.name,
        interests: d.interest,
        id: d.id,
      }));
    },
  });

  useEffect(() => {
    if (!expertsLoading && !stakeholdersLoading && !initiativesLoading) {
      const filteredItems = [...initiatives, ...stakeholders, ...experts]
        ?.filter((item) => item.location?.lng && item.location?.lat)
        ?.filter((item) => (mapFilter ? `${item?.type}s` === mapFilter : true));

      setDataSource(getDataSourceMap(filteredItems));
    }
  }, [experts, initiatives, stakeholders, mapFilter, expertsLoading, initiativesLoading, stakeholdersLoading]);

  const layerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-color': [
        'match',
        ['get', 'type'],
        'stakeholder',
        '#5100ff',
        'initiative',
        '#ff9c50',
        'expert',
        '#ff6fa7',
        '#ccc',
      ],
      'circle-radius': 6,
      'circle-stroke-color': '#ffffff',
      'circle-stroke-opacity': 0.5,
    },
  };

  const [hoverInfo, setHoverInfo] = useState([]);

  const onClick = useCallback((event) => {
    const {
      point: { x, y },
      target,
    } = event;

    const buffer = 8;
    const features = target.queryRenderedFeatures(
      [
        [x - buffer, y - buffer],
        [x + buffer, y + buffer],
      ],
      {
        layers: ['point'],
      },
    );

    // prettier-ignore
    setHoverInfo(features.map((feature) => ({ feature, x, y })));
  }, []);

  const language = useLocaleStore((state) => state.language);

  return (
    <Map
      mapLib={import('mapbox-gl')}
      initialViewState={{
        longitude: 4.702338435631551,
        latitude: 50.877157351303836,
        zoom: 11.5,
      }}
      mapStyle="mapbox://styles/leuven2030/ckj04vwo20leg19o4qyhisaia"
      scrollZoom={false}
      interactiveLayerIds={['point']}
      onClick={onClick}
    >
      <GeolocateControl position="bottom-left" />
      <FullscreenControl position="top-right" />
      <NavigationControl position="bottom-right" />
      <ScaleControl position="bottom-left" />
      {dataSource && (
        <Source type="geojson" data={dataSource}>
          <Layer {...layerStyle} />
        </Source>
      )}
      {hoverInfo.length > 0 && (
        <Popup
          key={hoverInfo[0].feature.properties.id}
          anchor="top"
          longitude={Number(hoverInfo[0].feature.geometry.coordinates[0])}
          latitude={Number(hoverInfo[0].feature.geometry.coordinates[1])}
          onClose={() => setHoverInfo([])}
        >
          <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
            {hoverInfo.map((info) => (
              <Paper
                className="card-stakeholder"
                style={{ width: '200px', marginBottom: '8px', borderRadius: '0px', padding: '2px 0px'}}
                key={`${info.x} - ${info.y} - ${info.feature.properties.name}`}
              >
                <div
                  className={`font-sans-s font-700 color-${
                    info.feature.properties.type === 'stakeholder'
                      ? 'c2'
                      : info.feature.properties.type === 'initiative'
                      ? 'c3'
                      : 'c1'
                  }`}
                  style={{ textTransform: 'uppercase' }}
                >
                  {getTranslation({
                    key: info.feature.properties.type,
                    language,
                  })}
                </div>
                <p className="fw-bold">{info.feature.properties.name}</p>
                <LinkScrollTop to={`${info.feature.properties.type}s/${info.feature.properties.id}`}>
                  Open &#8599;
                </LinkScrollTop>
              </Paper>
            ))}
          </Box>
        </Popup>
      )}
    </Map>
  );
};

export default GeoMap;

import React from 'react';
import loaderSvg from '../assets/images/oval.svg';

const Loading = () => {
  return (
    <div className="loadingWrapper">
      <img src={loaderSvg} width={150} alt="loaderSvg" />
    </div>
  );
};

export default Loading;

export const getTranslation = ({ key, categoryKey, language }) => {
  if (categoryKey) {
    return dictionary[categoryKey][key] ? dictionary[categoryKey][key][language] : categoryKey;
  }
  return dictionary[key] ? dictionary[key][language] : key;
};

const dictionary = {
  interestslist: {
    logistics: {
      en: 'Logistics',
      nl: 'Logistiek',
    },
    organic: {
      en: 'Organic',
      nl: 'Biologische voeding',
    },
    biodiversity: {
      en: 'Biodiversity',
      nl: 'Biodiversiteit',
    },
    community_shared_agriculture: {
      en: 'Community Supported Agriculture',
      nl: 'Gemeenschapslandbouw (CSA)',
    },
    sustainable_technologies: {
      en: 'Sustainable Technologies',
      nl: 'Duurzame technologieën',
    },
    legislation_policy: {
      en: 'Legislation and Policy',
      nl: 'Wetgeving en beleid',
    },
    animal_based: {
      en: 'Animal Based',
      nl: 'Dierlijke voeding',
    },
    food_safety: {
      en: 'Food Safety',
      nl: 'Voedselveiligheid',
    },
    plant_based: {
      en: 'Plant Based',
      nl: 'Plantaardige voeding',
    },
    cultivation_technique: {
      en: 'Cultivation Techniques',
      nl: 'Teelttechniek en -tips',
    },
    short_supply: {
      en: 'Short Supply',
      nl: 'Korte keten',
    },
    health: {
      en: 'Health',
      nl: 'Gezonde voeding',
    },
    faird_trade: {
      en: 'Fair trade',
      nl: 'Eerlijke handel',
    },
    social_inclusion: {
      en: 'Social Inclusion',
      nl: 'Sociale inclusie',
    },
    waste_disposal: {
      en: 'Waste Disposal',
      nl: 'Afvalverwerking',
    },
    prevention_food_waste: {
      en: 'Prevention of food waste',
      nl: 'Preventie van voedselverspilling',
    },
    other: {
      en: 'Other',
      nl: 'Andere',
    },
  },
  interest_error: {
    en: 'At least one interest is required',
    nl: 'Ten minste één interessedomein is verplicht',
  },
  indicatorCategories: {
    social_sustainability: {
      en: 'Social Sustainability',
      nl: 'Sociaal',
    },
    economic_sustainability: {
      en: 'Economic',
      nl: 'Economisch',
    },
    education_awareness: {
      en: 'Education Awareness',
      nl: 'Educatie',
    },
    accessibility: {
      en: 'Accessibility',
      nl: 'Toegankelijkheid',
    },
    agricultural_sector: {
      en: 'Agricultural Sector',
      nl: 'Landbouwsector in beeld',
    },
    health_welbeing_nutrition_utilisation: {
      en: 'Health Welbeing Nutrition Utilisation',
      nl: 'Gezondheid en welzijn',
    },
    workforce_conditions: {
      en: 'Workforce Conditions',
      nl: 'Werkomstandigheden',
    },
    short_local_supply_chain: {
      en: 'Short Local Supply Chain',
      nl: 'Korte keten',
    },
    employment_opportunities: {
      en: 'Employment Opportunities',
      nl: 'Werkgelegenheid',
    },
    food_safety: {
      en: 'Food Safety',
      nl: 'Voedselveiligheid',
    },
    sustainable_food_consumption: {
      en: 'Sustainable Food Consumption',
      nl: 'Duurzame voedselconsumptie',
    },
    mutual_benefits: {
      en: 'Mutual Benefits',
      nl: 'Wederzijdse voordelen',
    },
    ghg_emissions: {
      en: 'GHG Emissions',
      nl: 'GHG Emissies',
    },
    ecosystem_management: {
      en: 'Ecosystem Management',
      nl: 'Ecosysteembeheer',
    },
    affordability: {
      en: 'Affordability',
      nl: 'Betaalbaarheid',
    },
    accessibility_affordability: {
      en: 'Accessibility and Affordability',
      nl: 'Toegankelijkheid en betaalbaarheid',
    },
    social_inclusion: {
      en: 'Social Inclusion',
      nl: 'Sociale inclusie',
    },
    short_food_supply_chain: {
      en: 'Short Food Supply Chain',
      nl: 'Korte keten',
    },
    local_economy: {
      en: 'Local Economy',
      nl: 'Lokale economie',
    },
    fair_trade: {
      en: 'Fair Trade',
      nl: 'Eerlijke handel',
    },
    environmental_sustainability: {
      en: 'Environmental Sustainability',
      nl: 'Milieu',
    },
    production: {
      en: 'Production',
      nl: 'Productie',
    },
    impact: {
      en: 'Impact',
      nl: 'Impact',
    },
    waste_disposal: {
      en: 'Waste Disposal',
      nl: 'Afvalverwerking',
    },
    urban_rural_integration: {
      en: 'Urban Rural Integration',
      nl: 'Relatie stad-platteland',
    },
    food_governance: {
      en: 'Policy',
      nl: 'Beleid',
    },
    governance: {
      en: 'Governance',
      nl: 'Beleid',
    },
    participation: {
      en: 'Participation',
      nl: 'Participatie',
    },
    food_loss_waste: {
      en: 'Food loss and waste',
      nl: 'Voedselverlies en -verspilling',
    },
  },
  data: {
    en: 'Your information',
    nl: 'Jouw gegevens',
  },
  indicators: {
    en: 'Indicators',
    nl: 'Indicatoren',
  },
  experts: {
    en: 'Experts',
    nl: 'Experten',
  },
  expert: {
    en: 'Expert',
    nl: 'Expert',
  },
  stakeholders: {
    en: 'Stakeholders',
    nl: 'Belanghebbenden',
  },
  stakeholder: {
    en: 'Stakeholder',
    nl: 'Belanghebbende',
  },
  initiatives: {
    en: 'Initiatives',
    nl: 'Initiatieven',
  },
  initiative: {
    en: 'Initiative',
    nl: 'Initiatief',
  },
  all_experts: {
    en: 'All experts',
    nl: 'Alle experten',
  },
  back_to_all_experts: {
    en: 'Back to all experts',
    nl: 'Terug naar alle experten',
  },
  expert_name: {
    en: 'Expert name',
    nl: 'Naam expert',
  },
  all_initiatives: {
    en: 'All initiatives',
    nl: 'Alle initiatieven',
  },
  back_to_all_initiatives: {
    en: 'Back to all initiatives',
    nl: 'Terug naar alle initiatieven',
  },
  initiative_name: {
    en: 'Initiative name',
    nl: 'Naam initiatief',
  },
  all_stakeholders: {
    en: 'All stakeholders',
    nl: 'Alle belanghebbenden',
  },
  back_to_all_stakeholders: {
    en: 'Back to all stakeholders',
    nl: 'Terug naar alle belanghebbenden',
  },
  stakeholder_name: {
    en: 'Stakeholder name',
    nl: 'Naam belanghebbende',
  },
  your_stakeholders: {
    en: 'Your stakeholders',
    nl: 'Jouw belanghebbenden',
  },
  your_experts: {
    en: 'Your experts',
    nl: 'Jouw experten',
  },
  your_initiatives: {
    en: 'Your initiatives',
    nl: 'Jouw initiatieven',
  },
  no_stakeholders: {
    en: 'You do not manage any stakeholders',
    nl: 'Je beheert geen belanghebbenden',
  },
  no_experts: {
    en: 'You do not manage any experts',
    nl: 'Je beheert geen experten',
  },
  no_initiatives: {
    en: 'You do not manage any initiatives',
    nl: 'Je beheert geen initiatieven',
  },
  logout: {
    en: 'Logout',
    nl: 'Uitloggen',
  },
  claim: {
    nl: 'Is deze info van jou, claim hieronder',
    en: 'Is this info yours, claim it below',
  },
  about: {
    en: 'About',
    nl: 'Over Ons',
  },
  more: {
    en: 'more',
    nl: 'meer',
  },
  sector: {
    en: 'Sector',
    nl: 'Sector',
  },
  learn_more: {
    en: 'Learn more',
    nl: 'Ontdek meer',
  },
  about_context: {
    en: 'Cities are key stakeholders in building a more sustainable society',
    nl: 'Steden zijn belangrijke belanghebbenden voor de opbouw van een meer duurzame samenleving',
  },
  about_efm: {
    en: 'Where does the EcoFoodMap fit in?',
    nl: 'Hoe past de EcoFoodMap in dit verhaal?',
  },
  indicators_dashboard: {
    en: 'Indicators',
    nl: 'Indicatoren',
  },
  indicators_context: {
    en: 'On this page you will find several indicators that describe the food system in Leuven. The indicators are divided into five different themes: Social, Economic, Urban-Rural Integration, Environment and Policy.',
    nl: 'Op deze pagina vind je verschillende indicatoren die het voedselsysteem in Leuven beschrijven. De indicatoren zijn opgedeeld in vijf verschillende thema’s: Sociaal, Economisch, Relatie stad-platteland, Milieu en Beleid.',
  },
  see_all: {
    en: 'See all',
    nl: 'Toon alle',
  },
  geo_map: {
    en: 'Geographic map',
    nl: 'Geografische kaart',
  },
  sys_map: {
    en: 'System map',
    nl: 'Systemische kaart',
  },
  mapping_food_system: {
    en: 'Mapping the food system',
    nl: 'Voedselsysteem in beeld',
  },
  food_ecosystem: {
    en: 'Food ecosystem',
    nl: 'Voedselnetwerk',
  },
  categories: {
    en: 'Categories',
    nl: 'Categorieën',
  },
  category_error: {
    en: 'At least one category is required',
    nl: 'Ten minste één categorie is verplicht',
  },
  search: {
    en: 'Search',
    nl: 'Zoeken',
  },
  'Sub-Categories': 'Subcategorieën',
  source: {
    en: 'Source',
    nl: 'Bron',
  },
  impact_areas: {
    en: 'Impact Areas',
    nl: 'Subcategorie',
  },
  responsible: {
    en: 'Responsible',
    nl: 'Verantwoordelijke data-verzameling',
  },
  download: {
    en: 'Download the data',
    nl: 'Download de gegevens',
  },
  related_indicators: {
    en: 'Related indicators',
    nl: 'Gerelateerde indicatoren',
  },
  related_stakeholders: {
    en: 'Related Stakeholders',
    nl: 'Gerelateerde Stakeholders',
  },
  related: {
    en: 'Related',
    nl: 'Gerelateerd',
  },
  description: {
    en: 'Description ',
    nl: 'Beschrijving',
  },
  description_nl: {
    en: 'Description (Dutch)',
    nl: 'Beschrijving',
  },
  description_en: {
    en: 'Description',
    nl: 'Beschrijving (Engels)',
  },
  description_error: {
    en: 'Dutch description is required',
    nl: 'Beschrijving is verplicht',
  },
  description_en_error: {
    en: 'Description is required',
    nl: 'Engelse beschrijving is verplicht',
  },
  email_error: {
    en: 'Invalid email address',
    nl: 'Ongeldig e-mailadres',
  },
  phone_number_error: {
    en: 'Invalid phone number',
    nl: 'Ongeldig telefoonnummer',
  },
  invalid_website_error: {
    en: 'Invalid URL',
    nl: 'Ongeldige URL',
  },
  geographic_area: {
    en: 'Geographic Area',
    nl: 'Geografisch schaalniveau',
  },
  'Initiatives ecosystem': 'Initiatieven',
  'Experts ecosystem': 'Experten',
  'Stakeholders ecosystem': 'Belanghebbenden',
  stakeholder_ecosystem: {
    en: 'Stakeholder ecosystem',
    nl: 'Ecosysteem van de belanghebbenden',
  },
  category: {
    en: 'Category',
    nl: 'Categorie',
  },
  event: {
    en: 'Event',
    nl: 'Evenement',
  },
  awareness_campaign: {
    en: 'Awareness campaign',
    nl: 'Sensibiliseringscampagne',
  },
  pilot_study_project: {
    en: 'Pilot study project',
    nl: 'Proefproject',
  },
  education_training: {
    en: 'Education training',
    nl: 'Opleiding',
  },
  network_platform: {
    en: 'Network platform',
    nl: 'Netwerk',
  },
  citizens_initiative: {
    en: 'Citizens initiative',
    nl: 'Burgerinitiatief',
  },
  interests: {
    en: 'Interests',
    nl: 'Interessedomein',
  },
  fairtrade: {
    en: 'Fairtrade',
    nl: 'Eerlijke handel',
  },
  fair_trade: { en: 'Faird trade', nl: 'Eerlijke handel' },
  social_inclusion: {
    en: 'Social inclusion',
    nl: 'Sociale inclusie',
  },
  organic: {
    en: 'Organic',
    nl: 'Biologische voeding',
  },
  sustainable_technologies: {
    en: 'Sustainable technologies',
    nl: 'Duurzame technologieën',
  },
  community_shared_agriculture: {
    en: 'Community supported agriculture',
    nl: 'Gemeenschapslandbouw (CSA)',
  },
  legislation_policy: {
    en: 'Legislation and policy',
    nl: 'Wetgeving en beleid',
  },
  animal_based: {
    en: 'Animal based',
    nl: 'Dierlijke voeding',
  },
  Biodiversity: 'Biodiversiteit',
  short_supplu: {
    en: 'Short supply',
    nl: 'Korte keten',
  },
  food_safety: {
    en: 'Food safety',
    nl: 'Voedselveiligheid',
  },
  Logistics: 'Logistiek',
  Logistisc: 'Logistiek',
  waste_disposal: {
    en: 'Waste disposal',
    nl: 'Afvalverwerking',
  },
  health: {
    en: 'Health',
    nl: 'Gezonde voeding',
  },
  plant_based: {
    en: 'Plant based',
    nl: 'Plantaardige voeding',
  },
  cultivation_technique: {
    en: 'Cultivation techniques',
    nl: 'Teelttechniek en tips',
  },
  target: {
    en: 'Target',
    nl: 'Doelgroep',
  },
  target_error: {
    en: 'Target audience is required',
    nl: 'Doelgroep is verplicht',
  },
  B2B: 'Business-to-Business',
  B2C: 'Business-to-Consumers',
  farms_food_production: {
    en: 'Farms food production',
    nl: 'Landbouwbedrijven en voedingsproducenten',
  },
  retailers: {
    en: 'Retailers',
    nl: 'Retailers',
  },
  horeca: {
    en: 'Horeca',
    nl: 'Horeca',
  },
  distribution: {
    en: 'Distribution',
    nl: 'Distributie',
  },
  schools: {
    en: 'Schools',
    nl: 'Scholen',
  },
  babies: {
    en: 'Babies',
    nl: "Baby's en peuters (0-4 jaar)",
  },
  children: { en: 'Children', nl: 'Kinderen (4-12 jaar)' },
  youth: { en: 'Youth', nl: 'Jongeren (12-18 jaar)' },
  students: { en: 'Students', nl: 'Studenten (18-25 jaar)' },
  elder: { en: 'Elder', nl: 'Ouderen (65+)' },
  low_income_households: {
    en: 'Low income households',
    nl: 'Mensen in kansarmoede',
  },
  disabled_people: { en: 'Disabled people', nl: 'Mensen met een beperking' },
  no_targets_given: { en: '', nl: '' },
  start_date: {
    en: 'Start date',
    nl: 'Startdatum',
  },
  end_date: { en: 'End date', nl: 'Einddatum' },
  opening_hour: { en: 'Opening hour', nl: 'Openingsuur' },
  closing_hour: { en: 'Closing hour', nl: 'Sluitingsuur' },
  event_date: { en: 'Event date', nl: 'Event date' },
  finished: {
    en: 'Finished',
    nl: 'Afgelopen',
  },
  location: { en: 'Location', nl: 'Locatie' },
  address: {
    en: 'Address',
    nl: 'Adres',
  },
  address_error: {
    en: 'Address is required',
    nl: 'Adres is verplicht',
  },
  capacity: {
    en: 'Capacity',
    nl: 'Capaciteit',
  },
  capacity_error: {
    en: 'Capacity is required',
    nl: 'Capaciteit is verplicht',
  },
  price: {
    en: 'Price',
    nl: 'Prijs',
  },
  free: {
    en: 'Free',
    nl: 'Gratis',
  },
  registration: {
    en: 'Registration',
    nl: 'Registratie',
  },
  funding: {
    en: 'Funding',
    nl: 'Sponsoring',
  },
  funding_error: {
    en: 'Funding is required',
    nl: 'Sponsoring is verplicht',
  },
  contacts: {
    en: 'Contact',
    nl: 'Contact',
  },
  email: {
    en: 'Email',
    nl: 'Email',
  },
  telephone: {
    en: 'Telephone',
    nl: 'Telefoon',
  },
  name: {
    en: 'Name',
    nl: 'Naam',
  },
  name_error: {
    en: 'Name is required',
    nl: 'Naam is verplicht',
  },
  activities: {
    en: 'Activities',
    nl: 'Activiteiten',
  },
  activity_error: {
    en: 'At least one activity is required',
    nl: 'Ten minste één activiteit is verplicht',
  },
  farm_food_production: {
    en: 'Farm food production',
    nl: 'Landbouw en voedingsproductie',
  },
  food_processing: {
    en: 'Food processing',
    nl: 'Voedselverwerking',
  },
  retail: {
    en: 'Retail',
    nl: 'Retail',
  },
  catering: {
    en: 'Catering and restaurants',
    nl: 'Catering en restaurants',
  },
  education: {
    en: 'Education',
    nl: 'Onderwijs',
  },
  government_agency: {
    en: 'Government agency',
    nl: 'Overheidsinstantie',
  },
  volunteer_organisation: {
    en: 'Volunteer organisation',
    nl: 'Vrijwilligersvereniging',
  },
  advisory_board: {
    en: 'Advisory board',
    nl: 'Adviesorgaan',
  },
  research_innovation: {
    en: 'Research innovation',
    nl: 'Onderzoek en innovatie',
  },
  interest_group: {
    en: 'Interest group',
    nl: 'Belangenvereniging',
  },
  knowledge_centre: {
    en: 'Knowledge centre',
    nl: 'Kennis- en expertisecentrum',
  },
  support_centre: {
    en: 'Support centre',
    nl: 'Steunpunt',
  },
  youth_comminty_work: {
    en: 'Youth comminty work',
    nl: 'Jeugd- en buurtwerking',
  },
  employment_agency: {
    en: 'Employment agency',
    nl: 'Uitzendbureau',
  },
  network: {
    en: 'Network',
    nl: 'Netwerkorganisatie',
  },
  website: {
    en: 'Website',
    nl: 'Website',
  },
  website_error: {
    en: 'Website is required',
    nl: 'Website is verplicht',
  },
  services: {
    en: 'Services',
    nl: 'Diensten',
  },
  products: {
    en: 'Products',
    nl: 'Producten',
  },
  vegetables: {
    en: 'Vegetables',
    nl: 'Groenten',
  },
  fruit: { en: 'Fruit', nl: 'Fruit' },
  meat: {
    en: 'Meat',
    nl: 'Vleesproducten',
  },
  staplings: {
    en: 'staplings',
    nl: 'staplings',
  },
  diary_products: { en: 'Diary products', nl: 'Zuivel' },
  dairy_products: { en: 'Diary products', nl: 'Zuivel' },
  drinks: { en: 'Drinks', nl: 'Dranken' },
  seeds_nuts: { en: 'Seeds nuts', nl: 'Zaden en noten' },
  other_regional: {
    en: 'Other regional products',
    nl: 'Andere verwerkte streekproducten',
  },
  prepared_meals: { en: 'Prepared meals', nl: 'Bereide maaltijden' },
  labelled_products: {
    en: 'Labelled products',
    nl: 'Producten met een label',
  },
  regional_product_label: {
    en: 'Regional product label',
    nl: 'Streekproductenlabel',
  },
  fairtrade_label: { en: 'Fairtrade label', nl: 'FairTrade label' },
  organic_label: { en: 'Organic label', nl: 'Biologisch label' },
  vegeterian_label: { en: 'Vegetarian label', nl: 'Vegetarisch label' },
  food_waste: { en: 'Food waste', nl: 'Voedselverspilling' },
  surplus: { en: 'Surplus', nl: 'Overschotten' },
  action_surplus: { en: 'Action surplus', nl: 'Voedseloverschotten' },
  donating: { en: 'Donating', nl: 'Schenken' },
  selling_reduced: {
    en: 'Selling at reduced price',
    nl: 'Aan verminderde prijs verkopen',
  },
  animal_feed: { en: 'Animal feed', nl: 'Gebruiken als dierenvoeding' },
  composting: {
    en: 'Composting',
    nl: 'Composteren (of sorteren ons GFT-afval)',
  },
  burn_dump: { en: 'Burn or dump', nl: 'Als afval verbranden of storten' },
  food_surplus: { en: 'Food surplus', nl: 'Voedseloverschotten' },
  opening_hours: {
    en: 'Opening hours',
    nl: 'Openingsuren',
  },
  monday: { en: 'Monday', nl: 'Maandag' },
  tuesday: { en: 'Tuesday', nl: 'Dinsdag' },
  wednesday: { en: 'Wednesday', nl: 'Woensdag' },
  thursday: { en: 'Thursday', nl: 'Donderdag' },
  friday: { en: 'Friday', nl: 'Vrijdag' },
  saturday: { en: 'Saturday', nl: 'Zaterdag' },
  sunday: { en: 'Sunday', nl: 'Zondag' },
  enterprise_size: {
    en: 'Enterprise size',
    nl: 'Grootte van de onderneming',
  },
  nace_code: {
    en: 'Nacecode',
    nl: 'NACE-code',
  },
  municipality_leuven: {
    en: 'Municipality of Leuven',
    nl: 'Gemeente Leuven',
  },
  flanders: {
    en: 'Flanders',
    nl: 'Vlaanderen',
  },
  leuven_counts: {
    en: 'Leuven counts ',
    nl: 'Leuven telt ',
  },
  and: {
    en: ' and ',
    nl: ' en ',
  },
  national: {
    en: 'National',
    nl: 'National',
  },
  flemish_brabant: {
    en: 'Flemish Brabant',
    nl: 'Vlaams-Brabant',
  },
  arrondissement_leuven: {
    en: 'Arrondissement of Leuven',
    nl: 'Arrondissement van Leuven',
  },
  submunicipality_leuven: {
    en: 'Submunicipality of Leuven',
    nl: 'Submunicipaliteit van Leuven',
  },
  population: {
    en: 'population',
    nl: 'bevolking',
  },
  population_growth: {
    en: 'population growth',
    nl: 'bevolkingsgroei',
  },
  surface: {
    en: 'surface',
    nl: 'oppervlakte',
  },
  child_poverty: {
    en: 'child poverty',
    nl: 'kinderarmoede',
  },
  unemployment: {
    en: 'unemployment',
    nl: 'werkloosheid',
  },
  number: {
    en: 'Amount',
    nl: 'Aantal',
  },
  euro_month: {
    en: 'euro/month',
    nl: 'euro/maand',
  },
  euro_person_year: {
    en: 'euro/person/year',
    nl: 'euro/persoon/jaar',
  },
  '%': {
    en: '%',
    nl: '%',
  },
  hectare: {
    en: 'Hectare',
    nl: 'Hectare',
  },
  perc_total_area: {
    en: '% of total area',
    nl: '% van totale oppervlakte',
  },
  ton_co2: {
    en: 'ton co2',
    nl: 'ton co2',
  },
  application_stakeholder: {
    en: 'Is your company or organisation an stakeholder in the food system of Leuven? Then add your information below.',
    nl: 'Is jouw bedrijf of organisatie een stakeholder in het Leuvense voedselsysteem? Voeg dan hieronder je informatie toe.',
  },
  application_expert: {
    en: 'Are you an expert within the food systeem of Leuven? Then add your information below.',
    nl: 'Ben je een expert in het Leuvense voedselsysteem? Voeg dan hieronder je informatie toe.',
  },
  application_initiative: {
    en: 'Do you have an initiative that strives for a sustainable food system within Leuven? Then add your information below.',
    nl: 'Heb jij een initiatief dat streeft naar een duurzaam Leuvens voedselsysteem? Voeg dan hieronder je informatie toe.',
  },
  node_linked: {
    en: 'This node is linked to:',
    nl: 'Dit knooppunt is gerelateerd aan:',
  },
  location_address: { en: 'Address', nl: 'Adres' },
  latitude: { en: 'Latitude', nl: 'Breedtegraad' },
  longitude: { en: 'Longitude', nl: 'Lengtegraad' },
  email_contact: { en: 'Email', nl: 'E-mail' },
  email_contact_error: {
    en: 'Email is required',
    nl: 'E-mail is verplicht',
  },
  name_contact: { en: 'Contact name', nl: 'Naam contact' },
  name_contact_error: {
    en: 'Contact name is required',
    nl: 'Naam contact is verplicht',
  },
  telephone_contact: { en: 'Telephone', nl: 'Telefoonnummer' },
  submit: { en: 'Submit your information', nl: 'Dien je gegevens in' },
  suggest: {
    nl: 'Zie je incorrecte informatie? Laat het ons weten!',
    en: 'Do you see incorrect information? Let us know!',
  },
  include: {
    en: 'include',
    nl: 'bevat',
  },
  explanation: {
    en: 'Click the categories to filter results',
    nl: 'Klik op de bovenstaande categorieën om je zoekresultaten te verfijnen',
  },
  privacy_cookies: {
    en: 'Privacy and Cookie Policy',
    nl: 'Privacy en Cookiebeleid',
  },
  privacy: {
    en: 'By submitting your information, you agree to',
    nl: 'Door je gegevens in te dienen verklaar je je akkoord met',
  },
  privacy_link: {
    en: 'our privacy and cookie policy.',
    nl: 'ons privacy- en cookiebeleid.',
  },
  privacy_error: {
    en: 'Permission to use your data is required!',
    nl: 'Toestemming om jouw persoonlijke data te verwerken is noodzakelijk!',
  },
  add_expert: {
    en: 'Add an expert',
    nl: 'Voeg een expert toe',
  },
  add_stakeholder: {
    en: 'Add an stakeholder',
    nl: 'Voeg een belanghebbende toe',
  },
  add_initiative: {
    en: 'Add an initiative',
    nl: 'Voeg een initiatief toe',
  },
  b2c: {
    en: 'Consumers (b2c)',
    nl: 'Consumenten (b2c)',
  },
  b2b: {
    en: 'Organisations (b2b)',
    nl: 'Organisaties (b2b)',
  },
  doe_mee: {
    en: 'Join',
    nl: 'Doe mee',
  },
  profile: {
    en: 'Profile',
    nl: 'Profiel',
  },
  user_info: {
    en: 'Your information',
    nl: 'Jouw gegevens',
  },
  close: {
    en: 'Close',
    nl: 'Sluiten',
  },
  filters: {
    en: 'Filters',
    nl: 'Filters',
  },
  update: {
    en: 'Update',
    nl: 'Bijwerken',
  },
  suggestion: {
    en: 'Suggestion',
    nl: 'Suggestie',
  },
  suggestion_send: {
    en: 'Suggestion sent! The owner of the information will receive your feedback in a moment',
    nl: 'Suggestie verzonden! De eigenaar van de informatie ontvangt binnenkort jouw feedback',
  },
  suggestion_error: {
    en: 'Suggestion text is required',
    nl: 'Suggestie tekst is verplicht',
  },
  username: {
    en: 'Add a username to your account',
    nl: 'Voeg een gebruikersnaam toe aan je account!',
  },
  username_error: {
    en: 'Username is required',
    nl: 'Gebruikersnaam is verplicht',
  },
  data_visibility: {
    en: 'Pay attention! Your data is visible to all visitors of the EcoFoodMap website.',
    nl: 'Let op! Je gegevens zijn zichtbaar voor alle bezoekers van de EcoFoodMap website.',
  },
  information: {
    en: 'Information',
    nl: 'Informatie',
  },
  your_information: {
    en: 'Your information',
    nl: 'Jouw gegevens',
  },
  thanks_for_logging_in: {
    en: "Thank you for logging in! This is where you'll find every expert, initiative and stakeholder you manage. You can add new experts, initiatives and stakeholders at the bottom of the page.",
    nl: 'Bedankt om in te loggen! Hier vind je alle experten, initiatieven en belanghebbenden die je beheert. Onderaan kan je nieuwe experten, initiatieven en belanghebbenden toevoegen.',
  },
  add_opening_hours: {
    en: 'Add opening hours',
    nl: 'Voeg openingsuren toe',
  },
  closed: {
    en: 'Closed',
    nl: 'Gesloten',
  },
  supported: {
    en: 'The EcoFoodMap has been supported by',
    nl: 'De EcoFoodMap is gesponsord door',
  },
  about_ecofoodmap: {
    en: 'About the EcoFoodMap',
    nl: 'Over de EcoFoodMap',
  },
  engagement: {
    en: 'Make your engagement visible now!',
    nl: 'Maak jouw betrokkenheid nu kenbaar!',
  },
  loggedin_succesfully: {
    en: 'You have successfully logged in. Please click on \'Your information\' to add and update experts, stakeholders and initiatives.',
    nl: 'Je bent succesvol ingelogd. Klik op \'Jouw gegevens\' om experten, belanghebbenden en initiatieven toe te voegen en aan te passen.',
  },
  contribution: {
    en: 'Your contribution matters too!',
    nl: 'Jouw inzet telt ook mee!',
  },
  contribution_text2: {
    en: 'Are you building a healthy food system in Leuven? Then we would like to show your information on the EcoFoodMap. Log in here to add your expert/initiative or stakeholder and manage your information. The admin may contact you for the management of your information.',
    nl: 'Bouw jij mee aan een gezond voedselsysteem in Leuven? Dan tonen we graag jouw informatie op de EcoFoodMap. Log hier in om jouw expert/ initiatief of belanghebbende toe te voegen en jouw informatie te beheren. Voor het beheer van je informatie kan de admin contact met je opnemen.',
  },
  contribution_text3: {
    en: 'You are about to log in or create an account. No password is required for this.',
    nl: 'Je staat op het punt om in te loggen of een account aan te maken. Hier is geen wachtwoord voor nodig.',
  },
  address_placeholder: {
    en: 'Streetname 123, PostalCode Cityname',
    nl: 'Straatnaam 123, Postcode Plaatsnaam',
  },
  unknown: {
    en: 'Unknown',
    nl: 'Onbekend',
  },
  period: {
    en: 'Period',
    nl: 'Periode',
  },
  message_add_forms: {
    en: 'Pay attention! If one of the two times is not filled in, it will be ignored!',
    nl: 'Let op! Als één van de twee tijden niet wordt ingevuld, wordt deze genegeerd!',
  },
  photo_upload_warning: {
    en: 'For the best user experience, we recommend uploading a square photo.',
    nl: 'Voor de beste gebruikerservaring raden we aan een vierkante foto te uploaden.',
  },
  something_wrong: {
    en: 'Something went wrong. Please try again or contact the site administrator.',
    nl: 'Helaas ging er iets mis. Probeer opnieuw of contacteer de beheerder van de site.',
  },
  submit_success: {
    en: 'Your information has been submitted successfully!',
    nl: 'Je gegevens zijn succesvol ingediend!',
  },
};

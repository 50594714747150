import React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import TrendTooltip from './TrendTooltip';
import EditButton from './EditButton';

const Trend = ({ card = false, data, indicator, language }) => {
  return (
    <div style={{ position: 'relative', width: '100%', height: card ? 75 : 300 }}>
      {!card && (
        <EditButton
          datasets={indicator?.dataset}
          indicator={indicator}
          language={language}
          style={{ position: 'absolute', top: 10, right: -10, zIndex: 10 }}
        />
      )}
      <ResponsiveContainer width={card ? 150 : '100%'} minHeight={card ? 75 : 300} min-width="100%">
        <LineChart data={data} margin={{ top: 10, right: 30, left: 20, bottom: 30 }}>
          {!card && (
            <>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="year" />
              <YAxis />
              <Tooltip content={<TrendTooltip payload={data} indicator={indicator} />} />
            </>
          )}
          <Line type="monotone" dataKey="data" strokeWidth={2} activeDot={{ r: 8, fill: '#5100FF' }} stroke="#5100FF" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Trend;

import { TextField } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getTranslation } from '../dictionary';
import { categoriesQueryOptions } from '../services/CategoryService';
import { createUserInitiative } from '../services/InitiativeService';
import { interestsQueryOptions } from '../services/InterestService';
import { targetsQueryOptions } from '../services/TargetService';
import { cleanData, getCoords, uploadImage } from '../utils';
import FileUpload from './FileUpload';
import InterestSelect from './InterestSelect';
import RenderSwitches from './RenderSwitches';

const FormInitiative = ({ language, setIsOpen }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedInterests, setSelectedInterests] = useState([]);

  const [categoryState, setCategoryState] = useState({
    event: false,
    awareness_campaign: false,
    pilot_study_project: false,
    education_training: false,
    network_platform: false,
    citizens_initiative: false,
  });

  const [targetState, setTargetState] = useState({
    b2c: false,
    b2b: false,
  });

  const [targetb2cState, setTargetb2cState] = useState({
    babies: false,
    children: false,
    youth: false,
    students: false,
    elder: false,
    low_income_households: false,
    disabled_people: false,
  });

  const [targetb2bState, setTargetb2bState] = useState({
    farms_food_production: false,
    retailers: false,
    horeca: false,
    distribution: false,
    schools: false,
  });

  const interests = useQuery(interestsQueryOptions);
  const categories = useQuery(categoriesQueryOptions);
  const targets = useQuery(targetsQueryOptions);
  const queryClient = useQueryClient();

  //TODO: If one of the 3 methods fails, the initiative should not be created and everything should be rolled back
  const onSubmit = async (data) => {
    const selectedTargets = getSelectedTargets();
    const selectedCategories = getSelectedCategories();
    const selectedInterests = getSelectedInterest();
    const user = JSON.parse(sessionStorage.getItem('stytch-user'));
    const imageId = await uploadImage(selectedFile);
    const locationId = await getCoords(data.address);

    const initiativeData = {
      data: {
        ...data,
        contact_email: data.contact_email.trim() || null,
        opening_hours: data.opening_hour && data.closing_hour ? `${data.opening_hour} - ${data.closing_hour}` : null,
        photo: imageId,
        ...(locationId && {
          location: {
            connect: [
              {
                id: locationId,
              },
            ],
          },
        }),
        admin_email: user?.emails[0]?.email,
        published_at: new Date().toISOString(),
        user_id: user?.user_id || null,
        interest: selectedInterests,
        finished: data.end_date ? data.end_date < new Date().toISOString() : false,
        category: selectedCategories,
        target: selectedTargets,
      },
    };

    createUserInitiative(cleanData(initiativeData))
      .then(() => {
        queryClient.invalidateQueries('initiatives');
        setIsOpen(false);
        enqueueSnackbar(getTranslation({ key: 'submit_success', language }), {
          variant: 'success',
        });
        document.getElementById('userInitiatives').scrollIntoView({ behavior: 'smooth' });
      })
      .catch((error) => {
        enqueueSnackbar(getTranslation({ key: 'something_wrong', language }), {
          variant: 'error',
        });
        console.error(error);
      });
  };

  const getSelectedTargets = () => {
    const result = [];
    handleTargetSelection(targetState, targets.data, result, targetb2cState, 'b2c', 'undeterminedB2C');
    handleTargetSelection(targetState, targets.data, result, targetb2bState, 'b2b', 'undeterminedB2B');
    return result;
  };

  const handleTargetSelection = (targetState, targets, result, targetSubset, type, undetermined) => {
    if (targetSubset.length === 0 && targetState[type]) {
      const target = targets.find((target) => target.name === undetermined);
      if (target) result.push({ id: target.id, name: target.name });
    } else {
      Object.keys(targetSubset).forEach((item) => {
        if (targetSubset[item]) {
          const target = targets.find((target) => target.name === item);
          if (target) result.push({ id: target.id, name: target.name });
        }
      });
    }
  };

  const getSelectedInterest = () => {
    return selectedInterests.map((interestKey) => ({
      id: interests.data.find((item) => item.name === interestKey)?.id,
      name: interestKey,
    }));
  };

  const getSelectedCategories = () => {
    return Object.keys(categoryState)
      .filter((category) => categoryState[category])
      .map((category) => ({
        id: categories.data.find((cat) => cat.name === category)?.id,
        name: category,
      }));
  };

  const handleSwitchChange = (stateSetter) => (event) => {
    stateSetter((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.checked,
    }));
  };

  return (
    <div className="form-container p-0">
      <section id="form-wrapper" className="w-100 mx-auto">
        <form onSubmit={handleSubmit(onSubmit)} className="container-fluid narrow w-100">
          <TextField
            id="name"
            label={`${getTranslation({ key: 'name', language })} *`}
            variant="standard"
            {...register('name', { required: true })}
            error={Boolean(errors.name)}
            helperText={errors.name && getTranslation({ key: 'name_error', language })}
          />

          <TextField
            id="description_nl"
            label={`${getTranslation({ key: 'description_nl', language })} *`}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description', { required: true })}
            error={Boolean(errors.description)}
            helperText={errors.description && getTranslation({ key: 'description_error', language })}
          />

          <TextField
            id="description_en"
            label={`${getTranslation({ key: 'description_en', language })} *`}
            multiline
            maxRows={12}
            variant="standard"
            {...register('description_en', { required: true })}
            error={Boolean(errors.description_en)}
            helperText={errors.description_en && getTranslation({ key: 'description_en_error', language })}
          />

          <TextField
            id="email"
            label={getTranslation({ key: 'email_contact', language }) + ' *'}
            variant="standard"
            {...register('contact_email', { required: true, pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/ })}
            error={Boolean(errors.contact_email)}
            helperText={errors.contact_email && getTranslation({ key: 'email_error', language })}
          />

          <TextField
            id="telephone"
            label={getTranslation({
              key: 'telephone_contact',
              language,
            })}
            variant="standard"
            {...register('contact_tel', {
              pattern: {
                value: /^\+?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,10}$/,
              },
            })}
            error={Boolean(errors.contact_tel)}
            helperText={errors.contact_tel ? getTranslation({ key: 'phone_number_error', language }) : ''}
          />

          <TextField
            id="website"
            label={getTranslation({ key: 'website', language })}
            variant="standard"
            {...register('website', {
              pattern: {
                value: /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
              },
            })}
            error={Boolean(errors.website)}
            helperText={errors.website ? getTranslation({ key: 'invalid_website_error', language }) : ''}
          />

          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="registration"
                label={getTranslation({ key: 'registration', language })}
                variant="standard"
                {...register('registration')}
                className="w-100"
              />
            </div>

            <div className="col-sm-6">
              <TextField
                id="price"
                label={getTranslation({ key: 'price', language })}
                type="number"
                variant="standard"
                inputProps={{ step: '0.01' }}
                {...register('price')}
                className="w-100"
                error={!!errors.price}
                helperText={errors.price ? errors.price.message : ''}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-6 col-sm-3">
              <TextField
                id="start_date"
                label={getTranslation({ key: 'start_date', language })}
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                {...register('start_date')}
              />
            </div>

            <div className="col-6 col-sm-3">
              <TextField
                id="end_date"
                label={getTranslation({ key: 'end_date', language })}
                type="date"
                variant="standard"
                InputLabelProps={{ shrink: true }}
                {...register('end_date')}
              />
            </div>

            <div className="col-6 col-sm-3">
              <TextField
                id="opening_hour"
                label={getTranslation({ key: 'opening_hour', language })}
                variant="standard"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...register('opening_hour')}
              />
            </div>

            <div className="col-6 col-sm-3">
              <TextField
                id="closing_hour"
                label={getTranslation({ key: 'closing_hour', language })}
                variant="standard"
                type="time"
                InputLabelProps={{ shrink: true }}
                {...register('closing_hour')}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6">
              <TextField
                id="funding"
                label={`${getTranslation({ key: 'funding', language })}`}
                variant="standard"
                {...register('funding')}
                error={Boolean(errors.funding)}
                helperText={errors.funding && getTranslation({ key: 'funding_error', language })}
                className="w-100"
              />
            </div>
            <div className="col-sm-6">
              <TextField
                id="capacity"
                label={`${getTranslation({ key: 'capacity', language })}`}
                variant="standard"
                type="number"
                {...register('capacity')}
                className="w-100"
              />
            </div>
          </div>

          <TextField
            id="address"
            label={`${getTranslation({ key: 'location_address', language })}`}
            placeholder={getTranslation({ key: 'address_placeholder', language })}
            variant="standard"
            {...register('address')}
            className="w-100"
          />

          <FileUpload file={selectedFile} setFile={setSelectedFile} />

          <InterestSelect
            language={language}
            interests={interests}
            selectedInterests={selectedInterests}
            setSelectedInterests={setSelectedInterests}
          />

          <RenderSwitches
            state={categoryState}
            label={getTranslation({ key: 'category', language })}
            handleChange={handleSwitchChange(setCategoryState)}
            control={control}
          />

          <RenderSwitches
            state={targetState}
            label={getTranslation({ key: 'target', language })}
            handleChange={handleSwitchChange(setTargetState)}
            control={control}
          />

          <div className="row mt-4">
            {targetState.b2c && (
              <div className="col-sm-6">
                <RenderSwitches
                  state={targetb2cState}
                  label={getTranslation({ key: 'b2c', language })}
                  handleChange={handleSwitchChange(setTargetb2cState)}
                  control={control}
                />
              </div>
            )}

            {targetState.b2b && (
              <div className="col-sm-6">
                <RenderSwitches
                  state={targetb2bState}
                  label={getTranslation({ key: 'b2b', language })}
                  handleChange={handleSwitchChange(setTargetb2bState)}
                  control={control}
                />
              </div>
            )}
          </div>
          <button className="button rounded-3 mt-3 w-100" type="submit">
            {getTranslation({ key: 'submit', language })}
          </button>
        </form>
      </section>
    </div>
  );
};

export default FormInitiative;

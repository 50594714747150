import { useQuery } from '@tanstack/react-query';
import React from 'react';
import Loading from '../components/Loading';
import { privacyQueryOptions } from '../services/PrivacyService';
import { useLocaleStore } from '../store';

const Privacy = () => {
  const language = useLocaleStore((state) => state.language);

  const { isLoading, data: privacy } = useQuery(privacyQueryOptions);

  if (isLoading) return <Loading />;
  return (
    <div className="container">
      <div className="Home">
        {language === 'nl' && (
          <div
            className="Privacy"
            dangerouslySetInnerHTML={{
              __html: privacy.data.attributes.content,
            }}
          />
        )}
        {language === 'en' && (
          <div
            className="Privacy"
            dangerouslySetInnerHTML={{
              __html: privacy.data.attributes.Content_en,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Privacy;

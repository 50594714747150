import { useQuery } from '@tanstack/react-query';
import React, { Fragment, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useLoaderData } from 'react-router-dom';
import IndicatorCard from '../components/IndicatorCard';
import LinkScrollTop from '../components/LinkScrollTop';
import Loading from '../components/Loading';
import RelatedVizExpanded from '../components/RelatedVizExpanded';
import Trend from '../components/Trend';
import TrendCompared from '../components/TrendCompared';
import { getTranslation } from '../dictionary';
import { getIndicatorWithGroup, indicatorsQueryOptions } from '../services/IndicatorService';
import { useLocaleStore } from '../store';
import { formatNumberWithCommas, validURL } from '../utils';
import EditButton from '../components/EditButton';

const RelatedCards = React.memo(({ indicator, group }) => {
  const language = useLocaleStore((state) => state.language);

  const { data: indicatorsSubset } = useQuery({
    ...indicatorsQueryOptions,
    select: (data) => {
      return data.filter((d) => d['impact_areas_id'] === indicator.impact_areas_id);
    },
  });

  var related = indicatorsSubset?.filter((e) => e.id !== indicator.id).filter((e) => e.group_id !== indicator.group_id);
  related = related ? related : group ? group : [];
  related.sort(() => Math.random() - 0.5); //Shuffle the array of related cards

  if (!related || !related?.length) {
    return false;
  }

  return (
    <section className="cards-wrapper pt-4 pb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-space mt-space-2">
              {getTranslation({ key: 'related_indicators', language })}:{' '}
              {getTranslation({
                key: indicator.impact_areas_id,
                categoryKey: 'indicatorCategories',
                language,
              })}
            </h2>
          </div>
        </div>
        <div className="row">
          {related &&
            related.slice(0, 6).map((d, j) => {
              //slice of 6 for clean formatting on the page
              return <IndicatorCard d={d} key={j} />;
            })}
        </div>
      </div>
    </section>
  );
});

const IndicatorPage = () => {
  const [hover, setHover] = useState(null);
  const language = useLocaleStore((state) => state.language);

  const { id } = useLoaderData();

  const { isLoading, error, data } = useQuery({
    ...indicatorsQueryOptions,
    select: (data) => getIndicatorWithGroup({ id: parseInt(id), indicators: data }),
  });

  if (isLoading) return <Loading />;

  if (error)
    return (
      <div className="stakeholder-page initiative">
        {
          <section className="py-4">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <h2 className="mb-1 mt-space">{error.message}</h2>
                </div>
              </div>
            </div>
          </section>
        }
      </div>
    );
  const { indicator, group } = data;
  const isLinked = indicator && indicator.linkeddataset ? true : false;

  return (
    <div className="Home">
      {indicator && (
        <section className="mt-space-2">
          <div className="container-fluid pt-1">
            <div className="row">
              <div className="col-12">
                <div className="label l mb-1">
                  <LinkScrollTop
                    className="d-inline-block pb-1"
                    to={`/indicators/indicator_category/${indicator.indicator_category}`}
                  >{`${
                    language === 'nl' ? indicator.indicator_category_nl : indicator.indicator_category_en
                  }`}</LinkScrollTop>
                  <span className="mx-1">›</span>
                  <LinkScrollTop
                    className="d-inline-block pb-1"
                    to={`/indicators/impact_areas_id/${indicator.impact_areas_id}`}
                  >{`${language === 'nl' ? indicator.impact_areas_nl : indicator.impact_areas_en}`}</LinkScrollTop>
                </div>
                <h2>{language === 'nl' ? indicator.indicator_nl : indicator.indicator_en}</h2>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="indicator-full">
        <div className="container-fluid">
          {indicator && ( // Graph or Chart
            <div className="row content">
              <div className="col-md-3">
                {isLinked ? (
                  <Fragment>
                    <p className="stat-text">{formatNumberWithCommas(indicator.linkeddataset.count)}</p>
                    <p className="stat-unit">{indicator.source} </p>
                  </Fragment>
                ) : (
                  <Fragment>
                    <p className="stat-text">
                      {hover
                        ? formatNumberWithCommas(hover.data)
                        : indicator.dataset !== null && indicator.dataset[indicator.dataset.length - 1]
                        ? formatNumberWithCommas(indicator.dataset[indicator.dataset.length - 1].data)
                        : 'n/a'}
                    </p>
                    <p className="stat-unit">
                      {indicator.dataset && indicator.dataset[0] // Large number label
                        ? getTranslation({
                            key: indicator.dataset[0].unit,
                            language: language,
                          })
                        : null}
                    </p>
                    <p className="stat-unit">
                      {hover // Lage number year
                        ? hover.year // hover; hovered year
                        : indicator.dataset && indicator.dataset[indicator.dataset.length - 1]
                        ? indicator.dataset[indicator.dataset.length - 1].year // No hover; most recent year
                        : null}
                    </p>
                  </Fragment>
                )}
              </div>

              {group.length ? (
                <div className="col-md-9">
                  {indicator.visualisation_function === 'timeline' ? (
                    <TrendCompared group={group} indicator={indicator} language={language} />
                  ) : (
                    <RelatedVizExpanded
                      data={group}
                      id={indicator.id}
                      indicator={indicator}
                      setHover={setHover}
                      hover={hover}
                      language={language}
                    />
                  )}
                  <div
                    style={{
                      height: '20px',
                      fontSize: '12px',
                      opacity: 0.5,
                      marginTop: '15px',
                      marginLeft: '30px',
                    }}
                  >
                    {indicator.visualisation_function === 'timeline' && hover && (
                      <span>{language === 'en' ? hover.indicator.indicator_en : hover.indicator.indicator_nl}</span>
                    )}
                  </div>
                </div>
              ) : (
                <div className="col-md-9">
                  {indicator.visualisation_function === 'timeline' ? (
                    <Trend // Data points in single-line graph
                      data={indicator.dataset}
                      card={false}
                      indicator={indicator}
                      language={language}
                    />
                  ) : (
                    <EditButton datasets={indicator.dataset} indicator={indicator} language={language} />
                  )}
                </div>
              )}
            </div>
          )}
          <div className="row content">
            <div className="col-12 py-5">
              <hr className="mt-space-2" />
            </div>
          </div>
          {indicator && ( // Extra info
            <div className="row content">
              <div className="col-md-12 two-cols-md">
                {/* Description info field */}
                {indicator.description_nl && (
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'description', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {language === 'nl' ? indicator.description_nl : indicator.description_en}
                    </div>
                  </div>
                )}

                {/* Impact area info field */}
                <div className="break-inside-avoid mr-xl-5 pt-1">
                  <div className="font-sans-s color-black-50 mt-4">
                    {getTranslation({ key: 'impact_areas', language })}
                  </div>
                  <div className="font-serif-m mt-1">
                    {language === 'nl' ? indicator.impact_areas_nl : indicator.impact_areas_en}
                  </div>
                </div>

                {indicator.source && (
                  /* Source info field */
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">{getTranslation({ key: 'source', language })}</div>
                    {validURL(indicator.source) ? (
                      <div className="font-serif-m mt-1">
                        <a href={indicator.source}>{indicator.source}</a>
                      </div>
                    ) : (
                      <div className="font-serif-m mt-1">{indicator.source}</div>
                    )}
                  </div>
                )}

                {indicator.main_responsible && (
                  /* Data responsible info field */
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'responsible', language })}
                    </div>
                    <div className="font-serif-m mt-1">{indicator.main_responsible}</div>
                  </div>
                )}

                {indicator.geographic_area && (
                  /* Geografic area info field */
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'geographic_area', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      {getTranslation({
                        key: indicator.geographic_area,
                        language,
                      })}
                    </div>
                  </div>
                )}

                {indicator && indicator.dataset.length > 0 && (
                  /*  */
                  <div className="break-inside-avoid mr-xl-5 pt-1">
                    <div className="font-sans-s color-black-50 mt-4">
                      {getTranslation({ key: 'download', language })}
                    </div>
                    <div className="font-serif-m mt-1">
                      <CSVLink
                        data={indicator.dataset}
                        filename={language === 'nl' ? indicator.indicator_nl : indicator.indicator_en}
                        className="btn-download custom-negative-margin-left"
                      >
                        Download &#x02193;
                      </CSVLink>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </section>

      {RelatedCards && <RelatedCards indicator={indicator} group={group} />}
    </div>
  );
};

export default IndicatorPage;

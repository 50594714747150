import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { FixedSizeList } from 'react-window';
import {
  connectDatasetToIndicator,
  createDataset,
  deleteDataset,
  updateDataset,
  updateIndicator,
} from '../services/IndicatorService';

// import { DevTool } from '@hookform/devtools';

export const ModalContent = ({ data, indicator, open, setOpen, language, group }) => {
  // Get QueryClient from the context
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, control, reset } = useForm({
    defaultValues: {
      datasets: data.map((dataset) => ({ ...dataset, datasetId: dataset.id })),
    },
  });

  // reset form after data changes from invalidation of query
  useEffect(() => {
    reset({
      datasets: data.map((dataset) => ({ ...dataset, datasetId: dataset.id })),
    });
    // Clean deleted datasets when data has changed
    setDeletedDatasets([]);
  }, [data, reset]);

  // Additional options {replace, append, prepend, remove, swap, move, insert}
  const datasets = useFieldArray({
    control,
    name: 'datasets',
  });

  const updateIndicatorMutation = useMutation({
    mutationFn: updateIndicator,
  });

  const createDataSetMutation = useMutation({
    mutationFn: createDataset,
    onSuccess: async ({ data }) => {
      await connectDatasetToIndicatorMutation.mutateAsync({ indicatorId: indicator.id, dataset: data.data });
    },
  });
  const updateDataSetMutation = useMutation({
    mutationFn: updateDataset,
  });

  const [deletedDatasets, setDeletedDatasets] = useState([]);
  const deleteDataSetMutation = useMutation({
    mutationFn: deleteDataset,
  });

  const connectDatasetToIndicatorMutation = useMutation({
    mutationFn: connectDatasetToIndicator,
    onError: async (error, { dataset }) => {
      // We we can't make relation then we will delete the dataset too.
      console.log('connectDatasetToIndicatorMutation', error);
      await deleteDataSetMutation.mutateAsync({ dataset });
    },
  });
  const processNewEntries = (newEntries) => {
    return Promise.all(newEntries.map((dataset) => createDataSetMutation.mutateAsync({ dataset })));
  };

  const processUpdatedEntries = (updatedEntries) => {
    return Promise.all(updatedEntries.map((dataset) => updateDataSetMutation.mutateAsync({ dataset })));
  };

  const processDeletedEntries = (deletedDatasets) => {
    // Do not remove unexisitng datasets via the filter
    return Promise.all(
      deletedDatasets
        .filter((dataset) => dataset.datasetId !== undefined)
        .map((dataset) => deleteDataSetMutation.mutateAsync({ dataset: { id: dataset.datasetId } })),
    );
  };

  const onSubmit = async (formData) => {
    setIsLoading(true);
    const newEntries = formData.datasets.filter((dataset) => dataset.id === undefined);
    const updatedEntries = formData.datasets.filter((dataset) => dataset.id !== undefined);

    try {
      if (deletedDatasets.length > 0) {
        await processDeletedEntries(deletedDatasets);
      }

      if (newEntries.length > 0) {
        await processNewEntries(newEntries);
      }

      if (updatedEntries.length > 0) {
        await processUpdatedEntries(updatedEntries);
      }

      if (formData.datasets.length > 1 && indicator.visualisation_function !== 'timeline') {
        await updateIndicatorMutation.mutateAsync({
          id: indicator.id,
          data: {
            visualization_function: 'timeline',
          },
        });
      }
      if (
        formData.datasets.length === 1 &&
        group?.filter((g) => g.id !== indicator.id).every((g) => g?.dataset?.length === 1) &&
        indicator.visualisation_function !== 'compare'
      ) {
        await updateIndicatorMutation.mutateAsync({
          id: indicator.id,
          data: {
            visualization_function: 'compare',
          },
        });
      }

      if (formData.datasets.length <= 1 && !group) {
        await updateIndicatorMutation.mutateAsync({
          id: indicator.id,
          data: {
            visualization_function: '',
          },
        });
      }
      enqueueSnackbar('Performed Successfully!', {
        variant: 'success',
      });
      setIsLoading(false);
      setOpen(false);
      queryClient.invalidateQueries({ queryKey: ['indicators'] });
    } catch (error) {
      console.error('Error processing datasets:', error);
      setIsLoading(false);
      // fail safe for someone who edited at the same time so it is fresh
      queryClient.invalidateQueries({ queryKey: ['indicators'] });
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="edit-modal-title"
      aria-describedby="edit-modal-description"
    >
      <Box className="modal-box p-2 col-11 col-md-6 col-lg-5">
        <Typography id="edit-modal-title" variant="h5" className="d-flex justify-content-center">
          {language === 'nl' ? indicator.indicator_nl : indicator.indicator_en}
        </Typography>
        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full d-flex flex-row justify-content-between my-2">
            <Button
              onClick={() => {
                reset({
                  datasets: data.map((dataset) => ({ ...dataset, datasetId: dataset.id })),
                });
                setDeletedDatasets([]);
                setOpen(false);
              }}
              variant="contained"
            >
              Discard
            </Button>
            <Button variant="contained" type="submit">
              {isLoading ? <CircularProgress size={20} color="secondary" /> : 'Publish'}
            </Button>
          </div>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                <FixedSizeList
                  className="List"
                  itemData={datasets.fields}
                  itemCount={datasets.fields?.length}
                  itemSize={90}
                  width="100%"
                  height={3 * 90}
                >
                  {({ index, style, data }) => (
                    <Row
                      index={index}
                      style={style}
                      fields={data}
                      datasets={datasets}
                      register={register}
                      setDeletedDatasets={setDeletedDatasets}
                    />
                  )}
                </FixedSizeList>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <DevTool control={control} /> */}
        </form>

        <div className="d-flex justify-content-center">
          <Button
            startIcon={<AddCircleOutlineIcon />}
            className="mt-2"
            variant="contained"
            onClick={() => datasets.append({ unit: data?.[0]?.unit ? data?.[0].unit : 'number', data: 0, year: 0 })}
          >
            Add
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
const Row = ({ fields, index, style, datasets, register, setDeletedDatasets }) => {
  return (
    <TableRow key={index} style={style}>
      <TableCell align="left">
        <>
          <IconButton size="small" disabled={index === 0}>
            <ArrowDropUpIcon
              onClick={() => {
                if (index !== 0) datasets.move(index, index - 1);
              }}
            />
          </IconButton>
          <IconButton size="small" disabled={index === fields.length - 1}>
            <ArrowDropDownIcon
              onClick={() => {
                if (index !== fields.length - 1) datasets.move(index, index + 1);
              }}
            />
          </IconButton>
        </>
      </TableCell>
      <TableCell>
        <TextField
          aria-label="Dataset input"
          placeholder="Type a number…"
          label="Value"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ step: '0.01' }}
          defaultValue={fields[index].data}
          key={fields[index].id}
          {...register(`datasets.${index}.data`, { valueAsNumber: true })}
          className="indicator-input"
          required={true}
        />
      </TableCell>
      <TableCell>
        <TextField
          label="Year"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          defaultValue={fields[index].year}
          key={fields[index].id}
          {...register(`datasets.${index}.year`, { valueAsNumber: true })}
          className="indicator-input"
          required={true}
        />
      </TableCell>
      <TableCell align="right">
        <IconButton>
          <DeleteIcon
            onClick={() => {
              setDeletedDatasets((prev) => [...prev, fields[index]]);
              datasets.remove(index);
            }}
          />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

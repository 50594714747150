import React, { useState } from 'react';
import { getTranslation } from '../dictionary';
import { apiUrl } from '../settings';
import { useLocaleStore } from '../store';
import { getPhotoIfPresent } from '../utils';
import LinkScrollTop from './LinkScrollTop';
import UpdateInitiative from './UpdateInitiative';

const InitiativeCard = ({ d }) => {
  const language = useLocaleStore((state) => state.language);
  const location = window.location.pathname;
  const maxWords = 22;

  const translatedDescription = language === 'en' ? d.description_en : d.description;
  const description = translatedDescription
    ? translatedDescription.split(' ').slice(0, maxWords).join(' ')
    : 'No description.';

  const photo = getPhotoIfPresent(d);
  const imgStyle = {
    backgroundImage: `url(${apiUrl?.slice(0, -4)}${photo})`,
    //backgroundSize: 'contain', // Scales the image to fit within the container
    backgroundSize: 'cover', // Scales the image to cover the container
    backgroundPosition: 'center center', // Centers the image within the container
    backgroundRepeat: 'no-repeat', // Prevents the image from repeating
  };

  const [isOpen, setIsOpen] = useState(false);
  const toggleCollapsible = () => setIsOpen(!isOpen);

  const renderInterests = () => {
    const maxInterests = 3;
    const displayedInterests = d?.interest?.slice(0, maxInterests).map((interest, j) => {
      return getTranslation({
        key: typeof interest === 'string' ? interest : interest.name,
        categoryKey: 'interestslist',
        language,
      });
    });

    if (d?.interest?.length > maxInterests) {
      displayedInterests.push(`${d.interest.length - maxInterests} ${getTranslation({ key: 'more', language })}`);
    }

    return displayedInterests?.join(', ');
  };

  const isAuthenticated = sessionStorage.getItem('stytch-user');
  const isOwner = isAuthenticated && JSON.parse(isAuthenticated)?.user_id === d?.user_id;
  const showUpdateButton = isOwner && location === '/data';

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }} className="col-md-6 col-xl-4 card-stakeholder-col">
      <LinkScrollTop className="card-stakeholder" style={{ flex: 1 }} to={`/initiatives/${d.id}`}>
        <header>
          <div className="left">
            <div className="font-sans-s font-700 color-c3">
              {getTranslation({ key: 'initiative', language }).toUpperCase()}
            </div>
            <div className="card-title">{d.name}</div>
            <div className="font-sans-s color-blackish-80">{renderInterests()}</div>
          </div>
          {photo && (
            <div className="right">
              <div className="round-img thumb" style={imgStyle}></div>
            </div>
          )}
        </header>
        <div className="card-body">
          <div className="font-serif-m font-400">{description}</div>
        </div>
      </LinkScrollTop>
      {showUpdateButton && (
        <button className="font-serif-m font-400" onClick={toggleCollapsible}>
          {isOpen ? getTranslation({ key: 'close', language }) : getTranslation({ key: 'update', language })} &#9660;
        </button>
      )}
      {isOpen && (
        <div className="collapsible-content">
          <UpdateInitiative props={d} setIsOpen={setIsOpen} />
        </div>
      )}
    </div>
  );
};

export default InitiativeCard;

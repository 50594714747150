import { useQuery } from '@tanstack/react-query';
import React from 'react';
import img1 from '../assets/images/about-s-1.jpg';
import img2 from '../assets/images/about-s-2.jpg';
import map from '../assets/images/map.png';
import Statistics from '../components/Statistics';
import { getTranslation } from '../dictionary';
import { aboutQueryOptions } from '../services/AboutService';
import { useLocaleStore } from '../store';

function About() {
  const language = useLocaleStore((state) => state.language);
  const { data: about } = useQuery(aboutQueryOptions);

  return (
    <div className="about pt-5 pad-b">
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h2 className="mb-space">{getTranslation({ key: 'about_ecofoodmap', language })}</h2>
          </div>
        </div>
      </div>
      <section className="intro-image"></section>

      <div className="container-fluid pad pad-b">
        <div className="row">
          <div className="row">
            <h2 className="pr-lg-5 display-5">{getTranslation({ key: 'about_context', language })}</h2>
          </div>
          <div className="row">
            {language === 'nl' &&
              about?.Cities.split('\n').map((part, i) => (
                <p className="font-serif-m fs-5 mt-3" key={i}>
                  {part}
                </p>
              ))}
            {language === 'en' &&
              about?.Cities_en.split('\n').map((part, i) => (
                <p className="font-serif-m fs-5 mt-3" key={i}>
                  {part}
                </p>
              ))}
          </div>
        </div>
      </div>

      <section className="geographic-context">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-xl-4 text-center">
              <img className="img-fluid about-map" src={map} alt="Map Fluid" />
            </div>
            <div className="col-lg-6 col-xl-8">
              <Statistics />
            </div>
          </div>
        </div>
      </section>

      <div className="container-fluid pad-small">
        <div className="row">
          <div className="col-lg-6 mt-5">
            <h2 className="mb-3 pr-lg-5 pr-xl-0">{getTranslation({ key: 'about_efm', language })}</h2>
            {language === 'nl' &&
              about?.Content.split('\n').map((part, i) => (
                <p
                  key={i}
                  className="font-serif-m fs-5 mb-3 pr-lg-5 pr-xl-0"
                  dangerouslySetInnerHTML={{ __html: part }}
                />
              ))}
            {language === 'en' &&
              about?.Content_en.split('\n').map((part, i) => (
                <p
                  key={i}
                  className="font-serif-m fs-5 mb-3 pr-lg-5 pr-xl-0"
                  dangerouslySetInnerHTML={{ __html: part }}
                />
              ))}
          </div>
          <div className="col-lg-6 col-xl-4 offset-xl-2 mt-5">
            <div className="row">
              <div className="col-sm-6 col-lg-12 mb-space">
                <img className="img-fluid" src={img1} alt="Fluid 1" />
              </div>
              <div className="col-sm-6 col-lg-12">
                <img className="img-fluid" src={img2} alt="Fluid 2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
